import { emptySplitPhpApi as api } from '../emptyApi'
export const addTagTypes = [
  'Common',
  'BillingBasicCost',
  'Billing',
  'BillingCost',
  'CamAccounts',
  'Clients',
  'CourierRegisters',
  'DeliveryServices',
  'Drivers',
  'Goods',
  'Invoices',
  'Locations',
  'Routes',
  'RunRequests',
  'Shipments',
  'Statistics',
  'Vehicles',
] as const
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      getInternalActOfAcceptanceExport: build.query<
        GetInternalActOfAcceptanceExportApiResponse,
        GetInternalActOfAcceptanceExportApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/export/act-of-acceptance-delivery`,
          params: {
            runRequestId: queryArg.runRequestId,
            shipmentIds: queryArg.shipmentIds,
          },
        }),
        providesTags: ['Common'],
      }),
      getInternalBillingBasicCostsList: build.query<
        GetInternalBillingBasicCostsListApiResponse,
        GetInternalBillingBasicCostsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/basic-delivery-service/costs`,
          params: {
            type: queryArg['type'],
            courierAlias: queryArg.courierAlias,
          },
        }),
        providesTags: ['BillingBasicCost'],
      }),
      postInternalBillingBasicCosts: build.mutation<
        PostInternalBillingBasicCostsApiResponse,
        PostInternalBillingBasicCostsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/basic-delivery-service/costs`,
          method: 'POST',
          body: queryArg.basicCostsStoreRequest,
        }),
        invalidatesTags: ['BillingBasicCost'],
      }),
      getInternalBillingBasicCostById: build.query<
        GetInternalBillingBasicCostByIdApiResponse,
        GetInternalBillingBasicCostByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/basic-delivery-service/costs/${queryArg.id}`,
        }),
        providesTags: ['BillingBasicCost'],
      }),
      deleteInternalBillingBasicCostId: build.mutation<
        DeleteInternalBillingBasicCostIdApiResponse,
        DeleteInternalBillingBasicCostIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/basic-delivery-service/costs/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['BillingBasicCost'],
      }),
      updateInternalBillingBasicCostId: build.mutation<
        UpdateInternalBillingBasicCostIdApiResponse,
        UpdateInternalBillingBasicCostIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/basic-delivery-service/costs/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.basicCostsUpdateRequest,
        }),
        invalidatesTags: ['BillingBasicCost'],
      }),
      getInternalBasicDeliveryServiceCostsList: build.query<
        GetInternalBasicDeliveryServiceCostsListApiResponse,
        GetInternalBasicDeliveryServiceCostsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/basic-delivery-service-costs`,
          params: { type: queryArg['type'] },
        }),
        providesTags: ['Billing'],
      }),
      getInternalBillingCostsList: build.query<
        GetInternalBillingCostsListApiResponse,
        GetInternalBillingCostsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/delivery-service/costs`,
          params: {
            clientId: queryArg.clientId,
            type: queryArg['type'],
            courierAlias: queryArg.courierAlias,
          },
        }),
        providesTags: ['BillingCost'],
      }),
      postInternalBillingCosts: build.mutation<
        PostInternalBillingCostsApiResponse,
        PostInternalBillingCostsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/delivery-service/costs`,
          method: 'POST',
          body: queryArg.costsStoreRequest,
        }),
        invalidatesTags: ['BillingCost'],
      }),
      getInternalBillingCostById: build.query<
        GetInternalBillingCostByIdApiResponse,
        GetInternalBillingCostByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/delivery-service/costs/${queryArg.id}`,
        }),
        providesTags: ['BillingCost'],
      }),
      deleteInternalBillingCostId: build.mutation<
        DeleteInternalBillingCostIdApiResponse,
        DeleteInternalBillingCostIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/delivery-service/costs/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['BillingCost'],
      }),
      updateInternalBillingCostId: build.mutation<
        UpdateInternalBillingCostIdApiResponse,
        UpdateInternalBillingCostIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/delivery-service/costs/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.costsUpdateRequest,
        }),
        invalidatesTags: ['BillingCost'],
      }),
      getInternalDeliveryServiceCostsList: build.query<
        GetInternalDeliveryServiceCostsListApiResponse,
        GetInternalDeliveryServiceCostsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/billing/tariffs/delivery-service-costs`,
          params: {
            clientId: queryArg.clientId,
            type: queryArg['type'],
          },
        }),
        providesTags: ['Billing'],
      }),
      getInternalBillingReport: build.query<
        GetInternalBillingReportApiResponse,
        GetInternalBillingReportApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/dlv/export/billing-report`,
          params: {
            dlvOrderBatchInfoId: queryArg.dlvOrderBatchInfoId,
          },
        }),
        providesTags: ['Billing'],
      }),
      getInternalDictionariesAccounts: build.query<
        GetInternalDictionariesAccountsApiResponse,
        GetInternalDictionariesAccountsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/dictionaries/accounts`,
          params: {
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['CamAccounts'],
      }),
      getInternalDictionariesAccountById: build.query<
        GetInternalDictionariesAccountByIdApiResponse,
        GetInternalDictionariesAccountByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/dictionaries/accounts/${queryArg.id}`,
        }),
        providesTags: ['CamAccounts'],
      }),
      getInternalClientSettingsById: build.query<
        GetInternalClientSettingsByIdApiResponse,
        GetInternalClientSettingsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/client-settings`,
          params: { partnerId: queryArg.partnerId },
        }),
        providesTags: ['Clients'],
      }),
      getInternalClientsList: build.query<
        GetInternalClientsListApiResponse,
        GetInternalClientsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/clients`,
          params: {
            ids: queryArg.ids,
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Clients'],
      }),
      postInternalClients: build.mutation<
        PostInternalClientsApiResponse,
        PostInternalClientsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/clients`,
          method: 'POST',
          body: queryArg.clientStoreRequest,
        }),
        invalidatesTags: ['Clients'],
      }),
      getClientsById: build.query<
        GetClientsByIdApiResponse,
        GetClientsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/v1.1/clients/${queryArg.id}` }),
        providesTags: ['Clients'],
      }),
      putInternalClientsById: build.mutation<
        PutInternalClientsByIdApiResponse,
        PutInternalClientsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/clients/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.clientUpdateRequest,
        }),
        invalidatesTags: ['Clients'],
      }),
      deleteInternalClientsById: build.mutation<
        DeleteInternalClientsByIdApiResponse,
        DeleteInternalClientsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/clients/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Clients'],
      }),
      getInternalCourierRegistersList: build.query<
        GetInternalCourierRegistersListApiResponse,
        GetInternalCourierRegistersListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/dlv/courier-registers`,
          params: {
            courierCode: queryArg.courierCode,
            registryDate: queryArg.registryDate,
            query: queryArg.query,
            orderBy: queryArg.orderBy,
            order: queryArg.order,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['CourierRegisters'],
      }),
      postInternalCourierRegisters: build.mutation<
        PostInternalCourierRegistersApiResponse,
        PostInternalCourierRegistersApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/dlv/courier-registers`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['CourierRegisters'],
      }),
      getInternalCourierRegisterById: build.query<
        GetInternalCourierRegisterByIdApiResponse,
        GetInternalCourierRegisterByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/dlv/courier-registers/${queryArg.id}`,
        }),
        providesTags: ['CourierRegisters'],
      }),
      deleteInternalCourierRegisterById: build.mutation<
        DeleteInternalCourierRegisterByIdApiResponse,
        DeleteInternalCourierRegisterByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/dlv/courier-registers/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['CourierRegisters'],
      }),
      getInternalDeliveryServicesList: build.query<
        GetInternalDeliveryServicesListApiResponse,
        GetInternalDeliveryServicesListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/delivery-services`,
          params: {
            ids: queryArg.ids,
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
            isActive: queryArg.isActive,
          },
        }),
        providesTags: ['DeliveryServices'],
      }),
      postInternalDeliveryServices: build.mutation<
        PostInternalDeliveryServicesApiResponse,
        PostInternalDeliveryServicesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/delivery-services`,
          method: 'POST',
          body: queryArg.deliveryServiceStoreRequest,
        }),
        invalidatesTags: ['DeliveryServices'],
      }),
      getInternalDeliveryServicesById: build.query<
        GetInternalDeliveryServicesByIdApiResponse,
        GetInternalDeliveryServicesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/delivery-services/${queryArg.id}`,
        }),
        providesTags: ['DeliveryServices'],
      }),
      putInternalDeliveryServicesById: build.mutation<
        PutInternalDeliveryServicesByIdApiResponse,
        PutInternalDeliveryServicesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/delivery-services/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.deliveryServiceUpdateRequest,
        }),
        invalidatesTags: ['DeliveryServices'],
      }),
      deleteInternalDeliveryServicesById: build.mutation<
        DeleteInternalDeliveryServicesByIdApiResponse,
        DeleteInternalDeliveryServicesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/delivery-services/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['DeliveryServices'],
      }),
      getInternalDlvOrderListBatchInfo: build.query<
        GetInternalDlvOrderListBatchInfoApiResponse,
        GetInternalDlvOrderListBatchInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/dlv/order-batch-infos`,
          params: {
            orderBy: queryArg.orderBy,
            order: queryArg.order,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Billing'],
      }),
      storeInternalDlvOrderList: build.mutation<
        StoreInternalDlvOrderListApiResponse,
        StoreInternalDlvOrderListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/dlv/order-batch-infos`,
          method: 'POST',
          params: {
            client: queryArg.client,
            courierCode: queryArg.courierCode,
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
          },
        }),
        invalidatesTags: ['Billing'],
      }),
      getInternalDlvOrderBatchInfoById: build.query<
        GetInternalDlvOrderBatchInfoByIdApiResponse,
        GetInternalDlvOrderBatchInfoByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/dlv/order-batch-infos/${queryArg.id}`,
        }),
        providesTags: ['Billing'],
      }),
      getInternalDlvOrderList: build.query<
        GetInternalDlvOrderListApiResponse,
        GetInternalDlvOrderListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/dlv/order-list`,
          params: {
            client: queryArg.client,
            courierCode: queryArg.courierCode,
            startDate: queryArg.startDate,
            endDate: queryArg.endDate,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Billing'],
      }),
      getInternalDriversList: build.query<
        GetInternalDriversListApiResponse,
        GetInternalDriversListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/drivers`,
          params: {
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
            isActive: queryArg.isActive,
            withRoutesAtDate: queryArg.withRoutesAtDate,
          },
        }),
        providesTags: ['Drivers'],
      }),
      postInternalDrivers: build.mutation<
        PostInternalDriversApiResponse,
        PostInternalDriversApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/drivers`,
          method: 'POST',
          body: queryArg.driverStoreRequest,
        }),
        invalidatesTags: ['Drivers'],
      }),
      getInternalDriversById: build.query<
        GetInternalDriversByIdApiResponse,
        GetInternalDriversByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/drivers/${queryArg.id}`,
        }),
        providesTags: ['Drivers'],
      }),
      putInternalDriversById: build.mutation<
        PutInternalDriversByIdApiResponse,
        PutInternalDriversByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/drivers/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.driverUpdateRequest,
        }),
        invalidatesTags: ['Drivers'],
      }),
      deleteInternalDriversById: build.mutation<
        DeleteInternalDriversByIdApiResponse,
        DeleteInternalDriversByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/drivers/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Drivers'],
      }),
      getInternalGoodsList: build.query<
        GetInternalGoodsListApiResponse,
        GetInternalGoodsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/goods`,
          params: {
            partnerId: queryArg.partnerId,
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Goods'],
      }),
      postInternalGoods: build.mutation<
        PostInternalGoodsApiResponse,
        PostInternalGoodsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/goods`,
          method: 'POST',
          body: queryArg.goodStoreRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Goods'],
      }),
      getInternalGoodsById: build.query<
        GetInternalGoodsByIdApiResponse,
        GetInternalGoodsByIdApiArg
      >({
        query: (queryArg) => ({ url: `/v1.1/goods/${queryArg.id}` }),
        providesTags: ['Goods'],
      }),
      getInternalInvoicesList: build.query<
        GetInternalInvoicesListApiResponse,
        GetInternalInvoicesListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/invoices`,
          params: {
            clientIds: queryArg.clientIds,
            statuses: queryArg.statuses,
            createdDateFrom: queryArg.createdDateFrom,
            createdDateTo: queryArg.createdDateTo,
            periodFrom: queryArg.periodFrom,
            periodTo: queryArg.periodTo,
            orderBy: queryArg.orderBy,
            order: queryArg.order,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Invoices'],
      }),
      postInternalInvoices: build.mutation<
        PostInternalInvoicesApiResponse,
        PostInternalInvoicesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/invoices`,
          method: 'POST',
          body: queryArg.body,
        }),
        invalidatesTags: ['Invoices'],
      }),
      patchInternalInvoicesUpdateAmountPaid: build.mutation<
        PatchInternalInvoicesUpdateAmountPaidApiResponse,
        PatchInternalInvoicesUpdateAmountPaidApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/invoices/${queryArg.invoiceId}`,
          method: 'PATCH',
          body: queryArg.invoiceUpdateAmountPaidRequest,
        }),
        invalidatesTags: ['Invoices'],
      }),
      getInternalLocationsList: build.query<
        GetInternalLocationsListApiResponse,
        GetInternalLocationsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/locations`,
          params: {
            type: queryArg['type'],
            partnerId: queryArg.partnerId,
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Locations'],
      }),
      putInternalLocations: build.mutation<
        PutInternalLocationsApiResponse,
        PutInternalLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/locations`,
          method: 'PUT',
          body: queryArg.locationStoreRequest,
        }),
        invalidatesTags: ['Locations'],
      }),
      postInternalLocations: build.mutation<
        PostInternalLocationsApiResponse,
        PostInternalLocationsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/locations`,
          method: 'POST',
          body: queryArg.locationStoreRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Locations'],
      }),
      getInternalLocationsById: build.query<
        GetInternalLocationsByIdApiResponse,
        GetInternalLocationsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/locations/${queryArg.id}`,
        }),
        providesTags: ['Locations'],
      }),
      getInternalCanHaveQubyWarehouse: build.query<
        GetInternalCanHaveQubyWarehouseApiResponse,
        GetInternalCanHaveQubyWarehouseApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/can-have-quby-warehouse`,
          params: {
            pickupLocationId: queryArg.pickupLocationId,
            deliveryLocationId: queryArg.deliveryLocationId,
            partnerId: queryArg.partnerId,
          },
        }),
        providesTags: ['Locations'],
      }),
      getInternalRouteListExport: build.query<
        GetInternalRouteListExportApiResponse,
        GetInternalRouteListExportApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/routes/${queryArg.id}/export/route-list`,
        }),
        providesTags: ['Routes'],
      }),
      getInternalRoutesList: build.query<
        GetInternalRoutesListApiResponse,
        GetInternalRoutesListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/routes`,
          params: {
            routeDate: queryArg.routeDate,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Routes'],
      }),
      createInternalRoute: build.mutation<
        CreateInternalRouteApiResponse,
        CreateInternalRouteApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/routes`,
          method: 'POST',
          body: queryArg.routeStoreRequest,
        }),
        invalidatesTags: ['Routes'],
      }),
      getInternalRouteById: build.query<
        GetInternalRouteByIdApiResponse,
        GetInternalRouteByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/routes/${queryArg.id}`,
        }),
        providesTags: ['Routes'],
      }),
      deleteInternalRouteById: build.mutation<
        DeleteInternalRouteByIdApiResponse,
        DeleteInternalRouteByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/routes/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Routes'],
      }),
      updateInternalRouteById: build.mutation<
        UpdateInternalRouteByIdApiResponse,
        UpdateInternalRouteByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/routes/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.routeUpdateRequest,
        }),
        invalidatesTags: ['Routes'],
      }),
      getInternalRunRequestsList: build.query<
        GetInternalRunRequestsListApiResponse,
        GetInternalRunRequestsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/run-requests`,
          params: {
            ids: queryArg.ids,
            query: queryArg.query,
            partnerIds: queryArg.partnerIds,
            deliveryServiceIds: queryArg.deliveryServiceIds,
            statuses: queryArg.statuses,
            types: queryArg.types,
            withoutRoutesAtDate: queryArg.withoutRoutesAtDate,
            createdDateFrom: queryArg.createdDateFrom,
            createdDateTo: queryArg.createdDateTo,
            updatedDateFrom: queryArg.updatedDateFrom,
            updatedDateTo: queryArg.updatedDateTo,
            arrivalDateFrom: queryArg.arrivalDateFrom,
            arrivalDateTo: queryArg.arrivalDateTo,
            orderBy: queryArg.orderBy,
            order: queryArg.order,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['RunRequests'],
      }),
      createInternalRunRequestsList: build.mutation<
        CreateInternalRunRequestsListApiResponse,
        CreateInternalRunRequestsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/run-requests`,
          method: 'POST',
          body: queryArg.runRequestStoreRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['RunRequests'],
      }),
      getInternalRunRequestsById: build.query<
        GetInternalRunRequestsByIdApiResponse,
        GetInternalRunRequestsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/run-requests/${queryArg.id}`,
        }),
        providesTags: ['RunRequests'],
      }),
      patchInternalRunRequest: build.mutation<
        PatchInternalRunRequestApiResponse,
        PatchInternalRunRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/run-requests/${queryArg.id}`,
          method: 'PATCH',
          body: queryArg.runRequestUpdateRequest,
        }),
        invalidatesTags: ['RunRequests'],
      }),
      updateStatusInternalRunRequest: build.mutation<
        UpdateStatusInternalRunRequestApiResponse,
        UpdateStatusInternalRunRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/run-requests/${queryArg.id}/status`,
          method: 'PATCH',
          body: queryArg.runRequestChangeStatusRequest,
        }),
        invalidatesTags: ['RunRequests'],
      }),
      transitionTiIdleRunInternalRunRequest: build.mutation<
        TransitionTiIdleRunInternalRunRequestApiResponse,
        TransitionTiIdleRunInternalRunRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/run-requests/${queryArg.id}/idle-run`,
          method: 'PATCH',
        }),
        invalidatesTags: ['RunRequests'],
      }),
      getInternalShipmentsList: build.query<
        GetInternalShipmentsListApiResponse,
        GetInternalShipmentsListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/shipments`,
          params: {
            ids: queryArg.ids,
            partnerIds: queryArg.partnerIds,
            deliveryServiceIds: queryArg.deliveryServiceIds,
            statuses: queryArg.statuses,
            types: queryArg.types,
            createdDateFrom: queryArg.createdDateFrom,
            createdDateTo: queryArg.createdDateTo,
            updatedDateFrom: queryArg.updatedDateFrom,
            updatedDateTo: queryArg.updatedDateTo,
            plannedDeliveryDateFrom: queryArg.plannedDeliveryDateFrom,
            plannedDeliveryDateTo: queryArg.plannedDeliveryDateTo,
            closedDateFrom: queryArg.closedDateFrom,
            closedDateTo: queryArg.closedDateTo,
            orderBy: queryArg.orderBy,
            with: queryArg['with'],
            order: queryArg.order,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }),
        providesTags: ['Shipments'],
      }),
      postInternalShipments: build.mutation<
        PostInternalShipmentsApiResponse,
        PostInternalShipmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/shipments`,
          method: 'POST',
          body: queryArg.shipmentStoreRequest,
          params: { partnerId: queryArg.partnerId },
        }),
        invalidatesTags: ['Shipments'],
      }),
      getInternalShipmentsById: build.query<
        GetInternalShipmentsByIdApiResponse,
        GetInternalShipmentsByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/shipments/${queryArg.id}`,
        }),
        providesTags: ['Shipments'],
      }),
      patchInternalShipments: build.mutation<
        PatchInternalShipmentsApiResponse,
        PatchInternalShipmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/shipments/${queryArg.shipmentId}`,
          method: 'PATCH',
          body: queryArg.shipmentUpdateRequest,
        }),
        invalidatesTags: ['Shipments'],
      }),
      updateStatusExternalShipment: build.mutation<
        UpdateStatusExternalShipmentApiResponse,
        UpdateStatusExternalShipmentApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/shipments/${queryArg.id}/status`,
          method: 'PATCH',
          body: queryArg.shipmentChangeStatusRequest,
        }),
        invalidatesTags: ['Shipments'],
      }),
      createRunRequestPrivateEndpoint: build.mutation<
        CreateRunRequestPrivateEndpointApiResponse,
        CreateRunRequestPrivateEndpointApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/shipments/${queryArg.shipmentId}/run-requests`,
          method: 'POST',
          body: queryArg.shipmentCreateRunRequestsRequest,
        }),
        invalidatesTags: ['Shipments'],
      }),
      getInternalShipmentsRegisterExport: build.query<
        GetInternalShipmentsRegisterExportApiResponse,
        GetInternalShipmentsRegisterExportApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/export/shipments-register`,
          params: {
            clientIds: queryArg.clientIds,
            statuses: queryArg.statuses,
            types: queryArg.types,
            createdDateFrom: queryArg.createdDateFrom,
            createdDateTo: queryArg.createdDateTo,
            updatedDateFrom: queryArg.updatedDateFrom,
            updatedDateTo: queryArg.updatedDateTo,
            desiredTimeFrom: queryArg.desiredTimeFrom,
            desiredTimeTo: queryArg.desiredTimeTo,
            actualDateFrom: queryArg.actualDateFrom,
            actualDateTo: queryArg.actualDateTo,
            invoiceIds: queryArg.invoiceIds,
            desiredColumn: queryArg.desiredColumn,
          },
        }),
        providesTags: ['Shipments'],
      }),
      getInternalStatisticsDeliveryServices: build.query<
        GetInternalStatisticsDeliveryServicesApiResponse,
        GetInternalStatisticsDeliveryServicesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/statistics/delivery-services`,
          params: {
            selectedTab: queryArg.selectedTab,
            deliveryServiceIds: queryArg.deliveryServiceIds,
            deliveredFrom: queryArg.deliveredFrom,
            deliveredTo: queryArg.deliveredTo,
          },
        }),
        providesTags: ['Statistics'],
      }),
      getInternalStatisticsRevenue: build.query<
        GetInternalStatisticsRevenueApiResponse,
        GetInternalStatisticsRevenueApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/statistics/revenue`,
          params: {
            selectedTab: queryArg.selectedTab,
            clientIds: queryArg.clientIds,
            deliveredFrom: queryArg.deliveredFrom,
            deliveredTo: queryArg.deliveredTo,
          },
        }),
        providesTags: ['Statistics'],
      }),
      getInternalDataClientCalculatorTariffs: build.query<
        GetInternalDataClientCalculatorTariffsApiResponse,
        GetInternalDataClientCalculatorTariffsApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/data-for-calculator-get-tariffs`,
          params: {
            partnerId: queryArg.partnerId,
            senderLocationId: queryArg.senderLocationId,
            receiverLocationId: queryArg.receiverLocationId,
          },
        }),
        providesTags: ['Shipments'],
      }),
      getInternalVehiclesList: build.query<
        GetInternalVehiclesListApiResponse,
        GetInternalVehiclesListApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/vehicles`,
          params: {
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            query: queryArg.query,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
            isActive: queryArg.isActive,
          },
        }),
        providesTags: ['Vehicles'],
      }),
      postInternalVehicles: build.mutation<
        PostInternalVehiclesApiResponse,
        PostInternalVehiclesApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/vehicles`,
          method: 'POST',
          body: queryArg.vehicleStoreRequest,
        }),
        invalidatesTags: ['Vehicles'],
      }),
      getInternalVehiclesById: build.query<
        GetInternalVehiclesByIdApiResponse,
        GetInternalVehiclesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/vehicles/${queryArg.id}`,
        }),
        providesTags: ['Vehicles'],
      }),
      putInternalVehiclesById: build.mutation<
        PutInternalVehiclesByIdApiResponse,
        PutInternalVehiclesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/vehicles/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.vehicleUpdateRequest,
        }),
        invalidatesTags: ['Vehicles'],
      }),
      deleteInternalVehiclesById: build.mutation<
        DeleteInternalVehiclesByIdApiResponse,
        DeleteInternalVehiclesByIdApiArg
      >({
        query: (queryArg) => ({
          url: `/v1.1/internal/vehicles/${queryArg.id}`,
          method: 'DELETE',
        }),
        invalidatesTags: ['Vehicles'],
      }),
    }),
    overrideExisting: false,
  })
export { injectedRtkApi as qubyApiPhp }
export type GetInternalActOfAcceptanceExportApiResponse =
  /** status 200 Successful getting act of acceptance file */ Blob
export type GetInternalActOfAcceptanceExportApiArg = {
  /** UUID of a run request */
  runRequestId?: string
  /** Array of shipment UUIDs. */
  shipmentIds?: string[]
}
export type GetInternalBillingBasicCostsListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: BasicCostsResource[]
  }
export type GetInternalBillingBasicCostsListApiArg = {
  /** Filter by billing type */
  type: BillingTypesEnum
  /** Filter by billing courierAlias */
  courierAlias: string
}
export type PostInternalBillingBasicCostsApiResponse =
  /** status 201 Created */ BasicCostsResource
export type PostInternalBillingBasicCostsApiArg = {
  basicCostsStoreRequest: BasicCostsStoreRequest
}
export type GetInternalBillingBasicCostByIdApiResponse =
  /** status 200 The data of the billing basic cost */ BasicCostsResource
export type GetInternalBillingBasicCostByIdApiArg = {
  /** Uuid of a billing basic cost */
  id: string
}
export type DeleteInternalBillingBasicCostIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalBillingBasicCostIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type UpdateInternalBillingBasicCostIdApiResponse =
  /** status 200 The data of the delivery basic service cost */ BasicCostsResource
export type UpdateInternalBillingBasicCostIdApiArg = {
  /** Uuid of a billing basic cost */
  id: string
  basicCostsUpdateRequest: BasicCostsUpdateRequest
}
export type GetInternalBasicDeliveryServiceCostsListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: BasicDeliveryServiceCostsResource[]
  }
export type GetInternalBasicDeliveryServiceCostsListApiArg = {
  /** Filter by billing type */
  type?: BillingTypesEnum
}
export type GetInternalBillingCostsListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: CostsResource[]
  }
export type GetInternalBillingCostsListApiArg = {
  /** Filter by client id */
  clientId: string
  /** Filter by billing type */
  type: BillingTypesEnum
  /** Filter by billing courierAlias */
  courierAlias: string
}
export type PostInternalBillingCostsApiResponse =
  /** status 201 Created */ CostsResource
export type PostInternalBillingCostsApiArg = {
  costsStoreRequest: CostsStoreRequest
}
export type GetInternalBillingCostByIdApiResponse =
  /** status 200 The data of the billing cost */ CostsResource
export type GetInternalBillingCostByIdApiArg = {
  /** Uuid of a billing cost */
  id: string
}
export type DeleteInternalBillingCostIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalBillingCostIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type UpdateInternalBillingCostIdApiResponse =
  /** status 200 The data of the billing cost */ CostsResource
export type UpdateInternalBillingCostIdApiArg = {
  /** Uuid of a billing cost */
  id: string
  costsUpdateRequest: CostsUpdateRequest
}
export type GetInternalDeliveryServiceCostsListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: DeliveryServiceCostsResource[]
  }
export type GetInternalDeliveryServiceCostsListApiArg = {
  /** Filter by client id */
  clientId?: string
  /** Filter by billing type */
  type?: BillingTypesEnum
}
export type GetInternalBillingReportApiResponse =
  /** status 200 Successful getting billing report file */ Blob
export type GetInternalBillingReportApiArg = {
  /** UUID of the order batch info */
  dlvOrderBatchInfoId: string
}
export type GetInternalDictionariesAccountsApiResponse =
  /** status 200 The data of the CAM accounts */ PaginationResponse & {
    data: CamAccountResource[]
  }
export type GetInternalDictionariesAccountsApiArg = {
  /** Search query */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type GetInternalDictionariesAccountByIdApiResponse =
  /** status 200 The data of the CAM account */ CamAccountResource
export type GetInternalDictionariesAccountByIdApiArg = {
  /** Id of the CAM account */
  id?: string
}
export type GetInternalClientSettingsByIdApiResponse =
  /** status 200 Client settings */ ClientSettingsResource
export type GetInternalClientSettingsByIdApiArg = {
  partnerId?: string
}
export type GetInternalClientsListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: ClientResource[]
  }
export type GetInternalClientsListApiArg = {
  /** Filter by ids of client */
  ids?: string[]
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type PostInternalClientsApiResponse =
  /** status 201 Successfully created */ ClientResource
export type PostInternalClientsApiArg = {
  clientStoreRequest: ClientStoreRequest
}
export type GetClientsByIdApiResponse = /** status 200 Success */ ClientResource
export type GetClientsByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type PutInternalClientsByIdApiResponse =
  /** status 202 Successfully updated */ ClientResource
export type PutInternalClientsByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
  clientUpdateRequest: ClientUpdateRequest
}
export type DeleteInternalClientsByIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalClientsByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type GetInternalCourierRegistersListApiResponse =
  /** status 200 The data of the courier registers */ PaginationResponse & {
    data: CourierRegisterResource[]
  }
export type GetInternalCourierRegistersListApiArg = {
  /** Search by courier code */
  courierCode?: CourierRegisterCourierCodesEnum
  /** Search by month in Y-m format */
  registryDate?: string
  /** Search by courier register order num or courier_num */
  query?: string
  /** Parameter to order the results by */
  orderBy?: CourierRegisterOrderByTypeEnum
  /** Parameter to order the results */
  order?: BaseOrderTypeEnum
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type PostInternalCourierRegistersApiResponse =
  /** status 201 Created */ CourierRegisterResource
export type PostInternalCourierRegistersApiArg = {
  body: {
    /** Файл реестра КС в xlsx формате */
    courierRegister: Blob
    courierCode: CourierRegisterCourierCodesEnum
    type: CourierRegisterTypesEnum
    /** Месяц реестра в формате Y-m */
    registryDate: string
  }
}
export type GetInternalCourierRegisterByIdApiResponse =
  /** status 200 The data of the run request */ CourierRegisterResource
export type GetInternalCourierRegisterByIdApiArg = {
  /** Uuid of a courier register */
  id?: string
}
export type DeleteInternalCourierRegisterByIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalCourierRegisterByIdApiArg = {
  /** Uuid of a courier register */
  id?: string
}
export type GetInternalDeliveryServicesListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: DeliveryServiceResource[]
  }
export type GetInternalDeliveryServicesListApiArg = {
  /** Filter by ids of delivery service */
  ids?: string[]
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
  /** filter by field isActive */
  isActive?: boolean
}
export type PostInternalDeliveryServicesApiResponse =
  /** status 201 Successfully created */ DeliveryServiceResource
export type PostInternalDeliveryServicesApiArg = {
  deliveryServiceStoreRequest: DeliveryServiceStoreRequest
}
export type GetInternalDeliveryServicesByIdApiResponse =
  /** status 200 Success */ DeliveryServiceResource
export type GetInternalDeliveryServicesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type PutInternalDeliveryServicesByIdApiResponse =
  /** status 202 Successfully updated */ DeliveryServiceResource
export type PutInternalDeliveryServicesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
  deliveryServiceUpdateRequest: DeliveryServiceUpdateRequest
}
export type DeleteInternalDeliveryServicesByIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalDeliveryServicesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type GetInternalDlvOrderListBatchInfoApiResponse =
  /** status 200 The data of the DLV order batch info */ PaginationResponse & {
    data: DlvOrderBatchInfoResource[]
  }
export type GetInternalDlvOrderListBatchInfoApiArg = {
  /** Parameter to order the results by */
  orderBy?: DlvOrderBatchInfoOrderByTypeEnum
  /** Parameter to order the results */
  order?: BaseOrderTypeEnum
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type StoreInternalDlvOrderListApiResponse =
  /** status 200 Batch task for saving orders in the queue was successfully created */ DlvOrderBatchInfoResource
export type StoreInternalDlvOrderListApiArg = {
  /** Email of client from CAM accounts */
  client?: string
  /** Courier code of DLV courier service */
  courierCode?: string
  /** Start date of DLV order */
  startDate: string
  /** End date of DLV order */
  endDate: string
}
export type GetInternalDlvOrderBatchInfoByIdApiResponse =
  /** status 200 The data of the DLV order batch info */ DlvOrderBatchInfoResource
export type GetInternalDlvOrderBatchInfoByIdApiArg = {
  /** Uuid of a order batch info */
  id?: string
}
export type GetInternalDlvOrderListApiResponse =
  /** status 200 The data of the DLV orders */ PaginationResponse & {
    data: DlvOrderResource[]
  }
export type GetInternalDlvOrderListApiArg = {
  /** Email of client from CAM accounts */
  client?: string
  /** Courier code of DLV courier service */
  courierCode?: string
  /** Start date of DLV order */
  startDate: string
  /** End date of DLV order */
  endDate: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type GetInternalDriversListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: DriverResource[]
  }
export type GetInternalDriversListApiArg = {
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
  /** filter by field isActive */
  isActive?: boolean
  /** Filters by drivers with routes on a given day */
  withRoutesAtDate?: string
}
export type PostInternalDriversApiResponse =
  /** status 201 Successfully created */ DriverResource
export type PostInternalDriversApiArg = {
  driverStoreRequest: DriverStoreRequest
}
export type GetInternalDriversByIdApiResponse =
  /** status 200 Success */ DriverResource
export type GetInternalDriversByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type PutInternalDriversByIdApiResponse =
  /** status 202 Successfully updated */ DriverResource
export type PutInternalDriversByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
  driverUpdateRequest: DriverUpdateRequest
}
export type DeleteInternalDriversByIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalDriversByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type GetInternalGoodsListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: GoodResource[]
  }
export type GetInternalGoodsListApiArg = {
  /** Uuid of a client. Only for calls by quby employees */
  partnerId: string
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type PostInternalGoodsApiResponse =
  /** status 201 Created */ GoodResource
export type PostInternalGoodsApiArg = {
  partnerId: string
  goodStoreRequest: GoodStoreRequest
}
export type GetInternalGoodsByIdApiResponse =
  /** status 200 Success */ GoodResource
export type GetInternalGoodsByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type GetInternalInvoicesListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: InvoiceResource[]
  }
export type GetInternalInvoicesListApiArg = {
  /** Filter by client ids */
  clientIds?: string[]
  /** Filter by invoice statuses */
  statuses?: InvoiceStatusEnum[]
  /** Created after or equal to provided date */
  createdDateFrom?: string
  /** Created before or equal to provided date */
  createdDateTo?: string
  /** Period of the invoice must intersect with provided date */
  periodFrom?: string
  /** Period of the invoice must intersect with provided date */
  periodTo?: string
  /** Parameter to order the results by */
  orderBy?: InvoiceOrderByEnum
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type PostInternalInvoicesApiResponse =
  /** status 201 Created */ InvoiceResource
export type PostInternalInvoicesApiArg = {
  body: {
    /** Uuid of a client. Only for calls by Quby employees */
    partnerId: string
    /** invoice date from */
    from: string
    /** invoice date to */
    to: string
    /** Register of the provided services in .xlsx format* */
    providedServicesRegister: any
    /** Comment for the created invoice */
    comment?: string
  }
}
export type PatchInternalInvoicesUpdateAmountPaidApiResponse =
  /** status 200 Successfully updated */ InvoiceResource
export type PatchInternalInvoicesUpdateAmountPaidApiArg = {
  /** Uuid of a invoice */
  invoiceId: string
  invoiceUpdateAmountPaidRequest: InvoiceUpdateAmountPaidRequest
}
export type GetInternalLocationsListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: LocationResource[]
  }
export type GetInternalLocationsListApiArg = {
  /** Type of entity */
  type: LocationTypes
  /** Id (UUID) of a model */
  partnerId?: string
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type PutInternalLocationsApiResponse =
  /** status 202 Data updated */ LocationResource
export type PutInternalLocationsApiArg = {
  /** Id (UUID) of a model */
  id: string
  locationStoreRequest: LocationStoreRequest
}
export type PostInternalLocationsApiResponse =
  /** status 201 Created */ LocationResource
export type PostInternalLocationsApiArg = {
  partnerId: string
  locationStoreRequest: LocationStoreRequest
}
export type GetInternalLocationsByIdApiResponse =
  /** status 200 Success */ LocationResource
export type GetInternalLocationsByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type GetInternalCanHaveQubyWarehouseApiResponse =
  /** status 200 Success */ {
    canHaveQubyWarehouse: boolean
  }
export type GetInternalCanHaveQubyWarehouseApiArg = {
  /** UUID of pickup location */
  pickupLocationId: string
  /** UUID of delivery location */
  deliveryLocationId: string
  /** UUID of client */
  partnerId: string
}
export type GetInternalRouteListExportApiResponse =
  /** status 200 Successful getting shipment export file */ Blob
export type GetInternalRouteListExportApiArg = {
  /** Uuid of a route */
  id?: string
}
export type GetInternalRoutesListApiResponse =
  /** status 200 The data of the routes */ PaginationResponse & {
    data: RouteResource[]
  }
export type GetInternalRoutesListApiArg = {
  /** Filter by route date */
  routeDate?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type CreateInternalRouteApiResponse =
  /** status 201 The data of the route */ RouteResource
export type CreateInternalRouteApiArg = {
  routeStoreRequest: RouteStoreRequest
}
export type GetInternalRouteByIdApiResponse =
  /** status 200 The data of the route */ RouteResource
export type GetInternalRouteByIdApiArg = {
  /** Uuid of a route */
  id?: string
}
export type DeleteInternalRouteByIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalRouteByIdApiArg = {
  /** Uuid of a route */
  id?: string
}
export type UpdateInternalRouteByIdApiResponse =
  /** status 200 The data of the route */ RouteResource
export type UpdateInternalRouteByIdApiArg = {
  /** Uuid of a route */
  id?: string
  routeUpdateRequest: RouteUpdateRequest
}
export type GetInternalRunRequestsListApiResponse =
  /** status 200 The data of the run requests */ PaginationResponse & {
    data: ShortRunRequestResource[]
  }
export type GetInternalRunRequestsListApiArg = {
  /** Filter by ids of run requests */
  ids?: string[]
  /** Search run requests by id, ordinal number, comment or vehicle plate number */
  query?: string
  /** Filter by client ids */
  partnerIds?: string[]
  /** Filter by delivery service ids */
  deliveryServiceIds?: string[]
  /** Filter by run request statuses */
  statuses?: RunRequestStatusEnum[]
  /** Filter by run request types */
  types?: RunRequestTypesEnum[]
  /** Filters by arrival date and no route for a given day */
  withoutRoutesAtDate?: string
  /** Filter by run requests created after or equal to provided date */
  createdDateFrom?: string
  /** Filter by run requests created before or equal to provided date */
  createdDateTo?: string
  /** Filter by run requests updated after or equal to provided date */
  updatedDateFrom?: string
  /** Filter by run requests updated before or equal to provided date */
  updatedDateTo?: string
  /** Filter by run requests arrival date after or equal to provided date */
  arrivalDateFrom?: string
  /** Filter by run requests arrival date before or equal to provided date */
  arrivalDateTo?: string
  /** Parameter to order the results by */
  orderBy?: RunRequestOrderByTypeEnum
  /** Parameter to order the results */
  order?: BaseOrderTypeEnum
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type CreateInternalRunRequestsListApiResponse =
  /** status 200 The data of the run request */ RunRequestResource
export type CreateInternalRunRequestsListApiArg = {
  /** Uuid of a client */
  partnerId: string
  runRequestStoreRequest: RunRequestStoreRequest
}
export type GetInternalRunRequestsByIdApiResponse =
  /** status 200 The data of the run request */ RunRequestResource
export type GetInternalRunRequestsByIdApiArg = {
  /** Uuid or full ordinal number of a run request */
  id?: string
}
export type PatchInternalRunRequestApiResponse =
  /** status 200 The data of the run request */ RunRequestResource
export type PatchInternalRunRequestApiArg = {
  /** Uuid or full ordinal number of a run request */
  id?: string
  runRequestUpdateRequest: RunRequestUpdateRequest
}
export type UpdateStatusInternalRunRequestApiResponse =
  /** status 200 The data of the run request */ RunRequestResource
export type UpdateStatusInternalRunRequestApiArg = {
  /** Uuid or full ordinal number of a run request */
  id?: string
  runRequestChangeStatusRequest: RunRequestChangeStatusRequest
}
export type TransitionTiIdleRunInternalRunRequestApiResponse =
  /** status 200 The data of the run request */ RunRequestResource
export type TransitionTiIdleRunInternalRunRequestApiArg = {
  /** Uuid or full ordinal number of a run request */
  id?: string
}
export type GetInternalShipmentsListApiResponse =
  /** status 200 The data of the shipments */ PaginationResponse & {
    data: ShipmentAuthResource[]
  }
export type GetInternalShipmentsListApiArg = {
  /** Filter by ids of shipments */
  ids?: string[]
  /** Filter by client ids */
  partnerIds?: string[]
  /** Filter by delivery service ids */
  deliveryServiceIds?: string[]
  /** Filter by shipment statuses */
  statuses?: ShipmentStatusEnum[]
  /** Filter by shipment types */
  types?: ShipmentTypesEnum[]
  /** Filter by shipments created after or equal to provided date */
  createdDateFrom?: string
  /** Filter by shipments created before or equal to provided date */
  createdDateTo?: string
  /** Filter by shipments updated after or equal to provided date */
  updatedDateFrom?: string
  /** Filter by shipments updated before or equal to provided date */
  updatedDateTo?: string
  /** Filter by shipments planned for delivery after or equal to provided date */
  plannedDeliveryDateFrom?: string
  /** Filter by shipments planned for delivery before or equal to provided date */
  plannedDeliveryDateTo?: string
  /** Filter by shipments closed (completed) after or equal to provided date */
  closedDateFrom?: string
  /** Filter by shipments closed (completed) before or equal to provided date */
  closedDateTo?: string
  /** Parameter to order the results by */
  orderBy?: ShipmentOrderByTypeEnum
  /** Preload specific relationns for the response */
  with?: ShipmentRelationsEnum[]
  /** Parameter to order the results */
  order?: BaseOrderTypeEnum
  /** Search query */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
}
export type PostInternalShipmentsApiResponse =
  /** status 201 Successfully created shipment */ ShipmentAuthResource
export type PostInternalShipmentsApiArg = {
  /** Uuid of a client. Only for calls by Quby employees */
  partnerId: string
  shipmentStoreRequest: ShipmentStoreRequest
}
export type GetInternalShipmentsByIdApiResponse =
  /** status 200 The data of the shipments */ ShipmentAuthResource
export type GetInternalShipmentsByIdApiArg = {
  /** Uuid or full ordinal number of a shipment */
  id?: string
}
export type PatchInternalShipmentsApiResponse =
  /** status 201 Successfully updated shipment */ ShipmentAuthResource
export type PatchInternalShipmentsApiArg = {
  /** Uuid of a shipment */
  shipmentId: string
  shipmentUpdateRequest: ShipmentUpdateRequest
}
export type UpdateStatusExternalShipmentApiResponse =
  /** status 200 The data of the shipment */ ShipmentAuthResource
export type UpdateStatusExternalShipmentApiArg = {
  /** Uuid of a shipment */
  id?: string
  shipmentChangeStatusRequest: ShipmentChangeStatusRequest
}
export type CreateRunRequestPrivateEndpointApiResponse =
  /** status 201 Successful create new run request */ {
    data: RunRequestResource[]
  }
export type CreateRunRequestPrivateEndpointApiArg = {
  /** Uuid of a shipment */
  shipmentId: string
  shipmentCreateRunRequestsRequest: ShipmentCreateRunRequestsRequest
}
export type GetInternalShipmentsRegisterExportApiResponse =
  /** status 200 Successful getting shipment export file */ Blob
export type GetInternalShipmentsRegisterExportApiArg = {
  /** Filter by client ids */
  clientIds?: string[]
  /** Filter by shipment statuses */
  statuses?: ShipmentStatusEnum[]
  /** Filter by shipment types */
  types?: ShipmentTypesEnum[]
  /** Created after or equal to provided date */
  createdDateFrom: string
  /** "Created before or equal to provided date" */
  createdDateTo: string
  /** Filter by shipments updated after or equal to provided date */
  updatedDateFrom?: string
  /** Filter by shipments updated before or equal to provided date */
  updatedDateTo?: string
  /** Filter by shipments planned for delivery after or equal to provided date */
  desiredTimeFrom?: string
  /** Filter by shipments planned for delivery before or equal to provided date */
  desiredTimeTo?: string
  /** Filter by shipments actual for delivery after or equal to provided date */
  actualDateFrom?: string
  /** Filter by shipments actual for delivery before or equal to provided date */
  actualDateTo?: string
  /** array of invoice UUIDs related to the shipment */
  invoiceIds?: string[]
  /** Column list for XLSX file */
  desiredColumn: ShipmentsRegisterExportColumnsEnum[]
}
export type GetInternalStatisticsDeliveryServicesApiResponse =
  /** status 200 Success */ {
    filters: StatisticsDeliveryServicesFiltersResource
  } & {
    data: BaseStatisticDataResource[]
  }
export type GetInternalStatisticsDeliveryServicesApiArg = {
  /** Tab to display statistics delivery services */
  selectedTab: StatisticsDeliveryServicesTabsEnum
  /** Filter by delivery service ids */
  deliveryServiceIds?: string[]
  /** Delivered after or equal to provided date */
  deliveredFrom?: string
  /** Delivered before or equal to provided date */
  deliveredTo?: string
}
export type GetInternalStatisticsRevenueApiResponse =
  /** status 200 Success */ {
    filters: StatisticsRevenueFiltersResource
  } & {
    data: BaseStatisticDataResource[]
  }
export type GetInternalStatisticsRevenueApiArg = {
  /** Tab to display statistics revenue */
  selectedTab: StatisticsRevenueTabsEnum
  /** Filter by client ids */
  clientIds?: string[]
  /** Delivered after or equal to provided date */
  deliveredFrom?: string
  /** Delivered before or equal to provided date */
  deliveredTo?: string
}
export type GetInternalDataClientCalculatorTariffsApiResponse =
  /** status 201 The data of the tariff */ DataForCalculatorGetTariffsResource
export type GetInternalDataClientCalculatorTariffsApiArg = {
  /** Partner ID */
  partnerId: string
  /** senderLocationId */
  senderLocationId: string
  /** receiverLocationId */
  receiverLocationId: string
}
export type GetInternalVehiclesListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: VehicleResource[]
  }
export type GetInternalVehiclesListApiArg = {
  /** Order direction */
  order?: 'ASC' | 'DESC' | 'asc' | 'desc'
  /** Field to order by */
  orderBy?: string
  /** Query string */
  query?: string
  /** Current page number */
  currentPage?: number
  /** Items per page */
  perPage?: number
  /** filter by field isActive */
  isActive?: boolean
}
export type PostInternalVehiclesApiResponse =
  /** status 201 Successfully created */ VehicleResource
export type PostInternalVehiclesApiArg = {
  vehicleStoreRequest: VehicleStoreRequest
}
export type GetInternalVehiclesByIdApiResponse =
  /** status 200 Success */ VehicleResource
export type GetInternalVehiclesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type PutInternalVehiclesByIdApiResponse =
  /** status 202 Successfully updated */ VehicleResource
export type PutInternalVehiclesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
  vehicleUpdateRequest: VehicleUpdateRequest
}
export type DeleteInternalVehiclesByIdApiResponse =
  /** status 204 Successfully deleted */ void
export type DeleteInternalVehiclesByIdApiArg = {
  /** Id (UUID) of a model */
  id: string
}
export type PaginationResponse = {
  /** Metadata */
  meta: {
    currentPage: number
    totalPages: number
    perPage: number
    count: number
  }
}
export type BasicCostsResource = {
  /** cost ID (UUID) */
  id: string
  /** Delivery service basic cost ID (UUID) */
  billingBasicDeliveryServiceCostId?: string
  /** Min cost */
  minCost?: number
  /** Cost */
  cost: number
  /** Time from */
  timeFrom?: string
  /** Time to */
  timeTo?: string
  /** City fias id */
  cityFiasId?: string
  /** Region fias id */
  regionFiasId?: string
}
export type BillingTypesEnum =
  | 'INSURANCE'
  | 'AGENCY_PAY_CASH'
  | 'AGENCY_PAY_CARD'
  | 'DELIVERY_SURCHARGE'
  | 'RETURN_SURCHARGE'
export type BasicCostsStoreRequest = {
  /** Delivery service cost courier alias */
  courierAlias: string
  type: BillingTypesEnum
  /** Min cost */
  minCost?: number
  /** Cost */
  cost: number
  /** Time from */
  timeFrom?: string
  /** Time to */
  timeTo?: string
  /** City fias id */
  cityFiasId?: string
  /** Region fias id */
  regionFiasId?: string
}
export type BasicCostsUpdateRequest = {
  /** Min cost */
  minCost?: number
  /** Cost */
  cost: number
  /** Time from */
  timeFrom?: string
  /** Time to */
  timeTo?: string
  /** City fias id */
  cityFiasId?: string
  /** Region fias id */
  regionFiasId?: string
}
export type BasicDeliveryServiceCostsResource = {
  /** Delivery service cost ID (UUID) */
  id: string
  /** Delivery service cost courier alias */
  courierAlias: string
  type: BillingTypesEnum
  /** Translated Type */
  translatedType: string
  /** Flag indicating the availability of tariffs with expiry dates */
  isTimeLimitAvailable?: boolean
  /** Flag indicating the availability of tariffs with fias addresses */
  isGeoLimitAvailable?: boolean
  /** Min cost */
  minCost?: number
  /** Cost */
  cost?: number
}
export type CostsResource = {
  /** cost ID (UUID) */
  id: string
  /** Delivery service cost ID (UUID) */
  billingDeliveryServiceCostId?: string
  /** Min cost */
  minCost?: number
  /** Cost */
  cost: number
  /** Time from */
  timeFrom?: string
  /** Time to */
  timeTo?: string
  /** City fias id */
  cityFiasId?: string
  /** Region fias id */
  regionFiasId?: string
}
export type CostsStoreRequest = {
  /** Delivery service cost client ID */
  clientId: string
  /** Delivery service cost courier alias */
  courierAlias: string
  type: BillingTypesEnum
  /** Min cost */
  minCost?: number
  /** Cost */
  cost: number
  /** Time from */
  timeFrom?: string
  /** Time to */
  timeTo?: string
  /** City fias id */
  cityFiasId?: string
  /** Region fias id */
  regionFiasId?: string
}
export type CostsUpdateRequest = {
  /** Min cost */
  minCost?: number
  /** Cost */
  cost: number
  /** Time from */
  timeFrom?: string
  /** Time to */
  timeTo?: string
  /** City fias id */
  cityFiasId?: string
  /** Region fias id */
  regionFiasId?: string
}
export type DeliveryServiceCostsResource = {
  /** Delivery service cost ID (UUID) */
  id: string
  /** Delivery service cost client ID */
  clientId: string
  /** Delivery service cost courier alias */
  courierAlias: string
  type: BillingTypesEnum
  /** Translated Type */
  translatedType: string
  /** Flag indicating the availability of tariffs with expiry dates */
  isTimeLimitAvailable?: boolean
  /** Flag indicating the availability of tariffs with fias addresses */
  isGeoLimitAvailable?: boolean
  /** Min cost */
  minCost?: number
  /** Cost */
  cost?: number
}
export type CamAccountResource = {
  /** Account ID */
  id: string
  /** Account email */
  email: string
  /** Account legal organization name */
  legalOrganizationName?: string | null
  /** INN */
  inn?: string | null
}
export type ClientSettingsResource = {
  /** % от объявленной ценности, который будет начислен за страхование груза в МСК */
  cargoInsuranceDefaultPercent: number
  cargoInsuranceMinCharge: number
  cargoInsuranceRegionalPercent: number
  cashOnDeliveryCardPercent: number
  cashOnDeliveryMinCharge: number
  cashOnDeliveryPercent: number
  crateCost: number
  crateCostMin: number
  crateCostPercent: number
  defaultShipmentStatus: string
  discountPercent: number
  documentReturnShipmentAutoCreation: boolean
  documentReturningCost: number
  isCargoPackageBasedLoadOperations: boolean
  isDeliveryServiceVisible: boolean
  isDemo: boolean
  isVolumeWeightEnabled: boolean
  overpricingForExtraCargoCost: number
  pickupFboBoxMskCost: number
  pickupFboBoxMskRegionCost: number
  pickupFboPalletMskCost: number
  pickupFboPalletMskRegionCost: number
  providingScanDocumentsCost: number
  redeliveryPercent: number
  returnPercent: number
  shipmentAutoConfirmationEnabled: boolean
  shipmentConfirmationCost: number
  volumeWeightValue: number
  defaultPartnerWarehouseId: string
  palletFormationCost: number
  palletSellingCost: number
  hourOfOneTimeServicesCost: number
}
export type ClientUserResource = {
  /** ID (UUID) */
  id: string
  email: string
  fullName: string
}
export type ClientWarehouseResource = {
  /** ID (UUID) */
  id: string
  type?: string
  name: string | null
  companyName?: string | null
  fullName?: string
  addressId?: string
  addressString: string
  workingTimeFrom: string | null
  workingTimeTo: string | null
}
export type ClientResource = {
  /** Name of organization */
  legalName: string
  legalAddress: string
  actualAddress: string
  phoneNumber: string
  /** ФИО директора организации */
  director: string
  inn: string
  ogrn: string
  bankName: string
  /** БИК банка */
  bic: string
  /** Корреспондентский счет банка */
  correspondentAccount: string
  /** Расчетный счет клиента в банке */
  checkingAccount: string
  /** Статус работы с нами */
  status: 'ACTIVE' | 'DEMO'
  /** Client ID (UUID) */
  id: string
  /** Ordinal number of a client */
  ordinalNumber?: number
  /** Duplicate legal name */
  name: string
  /** Concatenation of legal form and legal name */
  fullName: string
  /** Legal form of organization */
  legalForm: '\u041E\u041E\u041E' | '\u0418\u041F'
  kpp: string
  okpo: string
  /** Номер договора с нами */
  contractNumber: string
  /** Дата договора с нами */
  contractDate: string
  /** Пользователи клиента */
  users: ClientUserResource[]
  /** Склады клиента */
  warehouses: ClientWarehouseResource[]
  /** Date and time when client was deleted */
  deletedAt?: string
  settings?: ClientSettingsResource
}
export type ClientUserRequest = {
  email: string
  password: string
  fullName: string
}
export type ClientWarehouseRequest = {
  name: string
  addressString: string
  phoneNumber: string
  workingTimeFrom: string
  workingTimeTo: string
}
export type ClientStoreRequest = {
  /** Name of organization */
  legalName: string
  legalAddress: string
  actualAddress: string
  phoneNumber: string
  /** ФИО директора организации */
  director: string
  inn: string
  ogrn: string
  bankName: string
  /** БИК банка */
  bic: string
  /** Корреспондентский счет банка */
  correspondentAccount: string
  /** Расчетный счет клиента в банке */
  checkingAccount: string
  /** Статус работы с нами */
  status?: 'ACTIVE' | 'DEMO'
  /** Legal form of organization */
  legalForm: '\u041E\u041E\u041E' | '\u0418\u041F'
  /** Дата договора с нами */
  contractDate: string
  /** Пользователи клиента */
  users: ClientUserRequest[]
  /** Склады клиента */
  warehouses: ClientWarehouseRequest[]
  /** Номер договора с нами */
  contractNumber?: string
}
export type ClientUpdateRequest = {
  /** Name of organization */
  legalName?: string
  legalAddress?: string
  actualAddress?: string
  phoneNumber?: string
  /** ФИО директора организации */
  director?: string
  inn?: string
  ogrn?: string
  bankName?: string
  /** БИК банка */
  bic?: string
  /** Корреспондентский счет банка */
  correspondentAccount?: string
  /** Расчетный счет клиента в банке */
  checkingAccount?: string
  /** Статус работы с нами */
  status?: 'ACTIVE' | 'DEMO'
}
export type CourierRegisterCourierCodesEnum = 'X5_Post' | 'HALVAV2'
export type CourierRegisterTypesEnum = 'ALL' | 'DELIVERY' | 'RETURN'
export type CourierRegisterResource = {
  /** Courier register ID (UUID) */
  id: string
  courierCode: CourierRegisterCourierCodesEnum
  type?: CourierRegisterTypesEnum
  /** Месяц реестра в формате Y-m */
  registryDate?: string
  /** Имя файла реестра */
  fileName?: string
  /** Путь к файлу реестра в s3 */
  registryPath?: string
  /** Ссылка на скачивание файла реестра в s3 */
  registryUrl?: string
  /** Количество заказов */
  courierRegisterOrdersCount?: number
  /** Количество возвратов */
  returnsCount?: number
  /** Количество расхождений */
  deviationsCount?: number
  /** Дата создания (загрузки) */
  createdAt: string
}
export type CourierRegisterOrderByTypeEnum = 'registryDate' | 'createdDate'
export type BaseOrderTypeEnum = 'asc' | 'ASC' | 'desc' | 'DESC'
export type CommonDeliveryServiceProperties = {
  /** Display name */
  name?: string
  /** Legal name */
  legalName?: string
  /** Url */
  url?: string
  /** Phone number */
  phoneNumber?: string
  /** Dlv contract id */
  dlvContractId?: string
  /** Доступен к использованию */
  isActive?: boolean
}
export type DeliveryServiceResource = CommonDeliveryServiceProperties & {
  /** Delivery Service`s UUID */
  id?: string
  /** createdAt */
  createdAt?: string
}
export type DeliveryServiceStoreRequest = CommonDeliveryServiceProperties
export type DeliveryServiceUpdateRequest = CommonDeliveryServiceProperties
export type JobBatchStatusEnum =
  | 'UNKNOWN'
  | 'IN_PROGRESS'
  | 'FINISHED'
  | 'CANCELLED'
export type JobBatchResource = {
  /** Id of the batch job */
  id?: string
  /** Name of the batch job */
  name?: string
  /** Amount of pending jobs */
  pendingJobs?: number
  /** Amount of processed jobs */
  processedJobs?: number
  /** Total amount of jobs */
  totalJobs?: number
  /** Progress of the batch job in percentage */
  progressPercent?: number
  /** Amount of failed jobs */
  failedJobs?: number
  status?: JobBatchStatusEnum
  /** Job batch translated status */
  translatedStatus?: string
  /** Flag indicating if butch is canceled */
  isCancelled?: boolean
  /** Flag indicating if butch is finished */
  isFinished?: boolean
  /** Date and time when batch job was created */
  createdAt?: string
  /** Date and time when batch job was canceled */
  cancelledAt?: string | null
  /** Date and time when batch job was finished */
  finishedAt?: string | null
}
export type DlvOrderBatchInfoResource = {
  /** Id of the order batch info */
  id: string
  /** Id of DLV client from CAM in email format */
  clientId?: string
  /** Courier code of DLV courier service */
  courierCode?: string
  /** Start date of DLV orders */
  startDate?: string
  /** End date of DLV orders */
  endDate?: string
  /** Id of the batch job */
  batchId: string
  batch?: JobBatchResource
  /** Date and time when batch job was created */
  createdAt?: string
  /** Date and time when batch job was updated */
  updatedAt?: string
}
export type DlvOrderBatchInfoOrderByTypeEnum =
  | 'id'
  | 'startDate'
  | 'endDate'
  | 'createdDate'
  | 'updatedDate'
export type DlvPaymentTypesEnum = 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
export type DlvCourierServicesEnum =
  | 'TRYING_PRICE'
  | 'PARTIAL_DELIVERY_PRICE'
  | 'CHECK_CONTENT_PRICE'
  | 'PERSONALLY_IN_HANDS_PRICE'
  | 'VERIFY_PRICE'
  | 'REVERSE_PRICE'
  | 'DAY_OFF_DELIVERY_PRICE'
  | 'LIFT_TO_DOOR_PRICE'
  | 'LIFT_TO_DOOR_MANUAL_PRICE'
  | 'BOX_NEEDED_PRICE'
  | 'TRYING'
  | 'PARTIAL_DELIVERY'
  | 'CHECK_CONTENT'
  | 'PERSONALLY_IN_HANDS'
  | 'VERIFY'
  | 'REVERSE'
  | 'DAY_OFF_DELIVERY'
  | 'LIFT_TO_DOOR'
  | 'LIFT_TO_DOOR_MANUAL'
  | 'BOX_NEEDED'
  | 'WAITING_ON_ADDRESS'
  | 'TEMPERATURE_REGIME'
  | 'DELIVERY_DATE_APPROVE'
  | 'CONTACTLESS_DELIVERY'
  | 'DANGEROUS_CARGO'
export type DlvOrderCourierServicesResource = {
  service?: DlvCourierServicesEnum
  /** Service price */
  price?: number
}
export type DlvOrderResource = {
  /** номер заказа DLV */
  num: string
  /** Дата и время выгрузки заказа в КС */
  date?: any
  /** Дата последнего статуса\дата доставки */
  deliveryDate?: any
  /** Код курьерской службы DLV */
  courierCode?: string | null
  /** Трек номер */
  courierNum?: string | null
  /** Вес заказа */
  weight?: number
  /** Адрес получателя */
  receiverAddress?: string | null
  /** Цена за доставку */
  deliveryPrice?: number
  /** Оценочная стоимость отправления */
  insuranceSum?: number
  /** Агентское вознаграждение */
  agentPrice?: number
  paymentType?: DlvPaymentTypesEnum
  /** Признак наложенного платежа */
  cod: boolean
  /** Список услуг DLV */
  courierServices?: DlvOrderCourierServicesResource[]
  /** Сумма наложенного платежа */
  cashOnDelivery?: number
  /** Цена за наложенный платеж */
  cashOnDeliveryPrice?: number
  /** Цена за оценочную стоимость */
  insurancePrice?: number
  /** Email клиента */
  client?: string
  /** Id контракта */
  contractId?: string
  /** Id продукта */
  productId?: string
  /** Флаг, является ли заказ зарубежным */
  isInternational?: boolean
  /** ФИАС id города доставки заказа */
  cityFias?: string
  /** ФИАС id региона доставки заказа */
  regionFias?: string
}
export type DriverResource = {
  /** Driver`s UUID */
  id: string
  /** Full name */
  fullName: string
  /** Водительские права */
  driverLicence: string
  /** Номер */
  phoneNumber: string
  /** Паспортные данные */
  passportData?: string
  /** Дата создания */
  createdAt: string
  /** ID кем создано */
  createdBy: string
  /** Заблокирован? */
  fired: boolean
}
export type DriverStoreRequest = {
  /** Full name */
  fullName: string
  /** Водительские права */
  driverLicence?: string | null
  /** Паспортные данные */
  passportData?: string | null
  /** Номер */
  phoneNumber: string
}
export type DriverUpdateRequest = DriverStoreRequest & {
  /** Заблокирован? */
  fired?: boolean
}
export type GoodResource = {
  /** Driver`s UUID */
  id: any
  /** Name */
  name: string
  /** VendorCode */
  vendorCode: string
  /** ExternalId */
  externalId: string
  /** VatRate */
  vatRate: string
  /** Price */
  price: string
}
export type BaseGoodData = {
  name?: string
  vendorCode?: string
  externalId?: string
  vatRate?: string
  price?: number
}
export type GoodStoreRequest = BaseGoodData
export type InvoiceStatus = 'NEW'
export type InvoiceResource = {
  /** Invoice ID (UUID) */
  id: string
  status: InvoiceStatus
  /** Invoice name */
  name: string
  /** Date From */
  dateFrom: string
  /** Date To */
  dateTo: string
  /** Sum */
  sum: number
  /** Amount Paid */
  amountPaid: number
  /** Comment */
  comment: string
  /** Date and time when shipment was created */
  createdAt: string
  /** Date and time when shipment was updated last time */
  updatedAt: string
  /** Temporary url to download provided Services Register */
  providedServicesRegisterUrl?: string
}
export type InvoiceStatusEnum = 'NEW' | 'SENT' | 'PARTIALLY_PAID' | 'PAID'
export type InvoiceOrderByEnum =
  | 'clientId'
  | 'dateFrom'
  | 'dateTo'
  | 'sum'
  | 'status'
  | 'amountPaid'
  | 'createdDate'
export type InvoiceUpdateAmountPaidRequest = {
  /** Amount Paid */
  amountPaid: number
}
export type LocationTypes =
  | 'CLIENT_WAREHOUSE'
  | 'QUBY_WAREHOUSE'
  | 'MARKETPLACE_WAREHOUSE'
  | 'ADDRESS'
export type LocationCommonProperties = {
  /** Location`s UUID */
  id: string
  type: LocationTypes
  /** fullName */
  name: string
  /** fullName */
  fullName: string
  /** companyName */
  companyName: string
  /** addressId`s UUID */
  addressId: string
  /** addressString */
  addressString: string
  floor: number
  /** phoneNumber */
  phoneNumber: string
  /** additionalPhoneNumber */
  additionalPhoneNumber: string
  /** workingTimeFrom */
  workingTimeFrom: string
  /** workingTimeTo */
  workingTimeTo: string
  /** comment */
  comment: string
}
export type LocationResource = LocationCommonProperties
export type Location = {
  fullName?: string
  phoneNumber?: string
  additionalPhoneNumber?: string
  comment?: string
  addressString?: string
  floor?: number
}
export type LocationStoreRequest = Location
export type VehicleCapacityVolumeResource = {
  /** height */
  height: number
  /** width */
  width: number
  /** depth */
  depth: number
}
export type VehicleCapacityResource = {
  /** weight */
  weight: number
  volume: VehicleCapacityVolumeResource
}
export type VehicleResource = {
  /** Driver`s UUID */
  id: string
  /** brand */
  brand: string
  /** plateNumber */
  plateNumber: string
  /** description */
  description: string
  capacity: VehicleCapacityResource
  /** numberSts */
  numberSts: string
  /** numberVin */
  numberVin: string
  /** createdAt */
  createdAt: string
  /** Активный? */
  isActive: boolean
}
export type ShortDeliveryServiceResource = {
  /** Delivery Service`s UUID */
  id: string
  /** Display name */
  name: string
}
export type RunRequestTypesEnum =
  | 'PICKUP'
  | 'LOCATION_PICKUP'
  | 'DELIVERY'
  | 'RETURN'
export type RunRequestStatusEnum =
  | 'NEW'
  | 'IN_PROGRESS'
  | 'DONE'
  | 'IDLE_RUN'
  | 'CANCELLED'
  | 'ERROR'
export type RunRequestArrivalMethodsEnum =
  | 'QUBY_CAR'
  | 'DELIVERY_SERVICE'
  | 'SELF_DELIVERY'
export type AddressCommonProperties = {
  /** Address ID (UUID) */
  id?: string
  unrestrictedValue?: string
  value?: string
}
export type LocationShortResource = {
  /** Location`s UUID */
  id: string
  type?: LocationTypes
  /** fullName */
  fullName?: string
  /** addressString */
  addressString?: string
  /** phoneNumber */
  phoneNumber?: string
}
export type TimeFrameData = {
  date?: string
  timeFrom?: string
  timeTo?: string
}
export type PickupData = {
  /** UUID of sender location. Could only be provided without `senderLocation` field */
  locationId: string
  location: LocationShortResource
  timeFrame: TimeFrameData
  /** Comment for pickup */
  comment?: string
}
export type DeliveryData = {
  /** UUID of receiver location. Could only be provided without `receiverLocation` field */
  locationId: string
  location: LocationShortResource
  timeFrame: TimeFrameData
  /** Comment for delivery */
  comment?: string
}
export type ShipmentAdditionalServicesEnum =
  | 'PICKUP_MANUAL_LIFT'
  | 'PICKUP_ELEVATOR_LIFT'
  | 'DELIVERY_MANUAL_LIFT'
  | 'DELIVERY_ELEVATOR_LIFT'
  | 'CARGO_INSURANCE'
  | 'CASH_ON_DELIVERY_CASH'
  | 'CASH_ON_DELIVERY_CARD'
  | 'DOCUMENTS_RETURN'
  | 'REVERSE_SHIPMENT'
  | 'SHIPMENT_CONFIRMATION'
  | 'PALLET_FORMATION'
  | 'PALLET_SELLING'
  | 'ONE_TIME_SERVICES'
  | 'SCANS'
  | 'CRATING'
export type ShipmentTypesEnum =
  | 'TO_THE_DOOR'
  | 'FROM_THE_DOOR'
  | 'DOOR_TO_DOOR'
  | 'DOOR_WAREHOUSE_DOOR'
  | 'FBO'
  | 'REVERSE_FBO'
  | 'DOCUMENTS_RETURN'
export type ShipmentStatusEnum =
  | 'AT_SENDER_PARSE_ERROR'
  | 'AT_SENDER_NEW'
  | 'AT_SENDER_PACKED'
  | 'AT_SENDER_AWAITING_SELF_PICKUP'
  | 'AT_SENDER_AWAITING_QUBY_PICKUP'
  | 'QUBY_VEHICLE_2_QUBY'
  | 'AT_QUBY_AWAITING_CONFIRMATION'
  | 'AT_QUBY_AWAITING_CORRECTION'
  | 'AT_QUBY_AWAITING_ROUTING'
  | 'AT_QUBY_AWAITING_DELIVERY'
  | 'AGENT_VEHICLE_2_AGENT'
  | 'AT_AGENT'
  | 'AGENT_VEHICLE_2_RECEIVER'
  | 'AGENT_ERROR'
  | 'RETURN_AGENT_VEHICLE_2_QUBY'
  | 'RETURN_AGENT_VEHICLE_2_SENDER'
  | 'AT_AGENT_LOST'
  | 'QUBY_VEHICLE_2_RECEIVER'
  | 'AT_RECEIVER_DONE'
  | 'RETURN_QUBY_VEHICLE_2_QUBY'
  | 'RETURN_AT_QUBY_AWAITING_CONFIRMATION'
  | 'RETURN_AT_QUBY_AWAITING_RETURN'
  | 'RETURN_QUBY_VEHICLE_2_SENDER'
  | 'AT_SENDER_RETURNED'
  | 'AT_SENDER_CANCELLED'
export type ShortClientResource = {
  /** ID клиента (UUID) */
  id: string
  /** Конкатенация legal form и legal name клиента */
  fullName: string
  /** Дата и время, когда клиент был удален */
  deletedAt: string
}
export type ShortShipmentInternalResource = {
  client?: ShortClientResource | null
  deliveryService?: ShortDeliveryServiceResource | null
  /** Should vehicle go directly from sender to receiver location (true), or be unloaded in QubyWarehouse after pickup */
  isDirectRun: boolean
  /** Amount of money quby will pay (or already paid) to the delivery service */
  deliveryCost: number
  /** Track number for shipment in external delivery service */
  deliveryServiceTrackNumber: string
  /** Physical weight in kilograms */
  weightInKilos: number
  /** Flag indicating whether a run request can be created for a shipment */
  isRunRequestCreationAllowed: boolean
}
export type ShortShipmentResource = {
  /** Seller of this shipment (usually for FBO purposes and cases where quby client aggregates multiple sellers) */
  seller: string
  /** Unique external (from client system) identifier for the shipment */
  externalId: string
  /** Unique identifier of FBO delivery. Could only be provided when one of locations is FBO marketplace */
  fboDeliveryNumber: string
  pickup: PickupData
  delivery: DeliveryData
  /** Array of additional services to be done or already done by quby */
  additionalServices: ShipmentAdditionalServicesEnum[]
  /** Сформировано паллет */
  numberOfPalletsToForm: number
  /** Кол-во паллет к продаже */
  numberOfPalletsToSell: number
  /** Кол-во часов разовых работ */
  hoursOfOneTimeServices: number
  /** Shipment ID (UUID) */
  id: string
  /** Full ordinal number in the format [client ordinal number]-[shipment ordinal number] */
  fullOrdinalNumber: string
  type: ShipmentTypesEnum
  status: ShipmentStatusEnum
  /** Date and time when shipment was created */
  createdAt: string
  /** Date and time when shipment received terminal status (returned, delivered, cancelled, etc) */
  closedAt: string | null
  internal?: ShortShipmentInternalResource
}
export type RunRequestCargoVolumesEnum = 'SMALL' | 'MEDIUM' | 'LARGE'
export type CargoPackageTypes = 'DOCUMENTS' | 'BOX' | 'PALLET' | 'PILE'
export type RunRequestCargoTypesEnum = 'BOX' | 'BULKY' | 'OVERSIZED'
export type Attachment = {
  id?: string
  fileName?: string
  filePath?: string
  /** Date and time when attachment was created */
  createdAt?: string
}
export type ShortVehicleResource = {
  id: string
  brand: string
  plateNumber: string
}
export type RunRequestCommonProperties = {
  /** Run request ID (UUID) */
  id: string
  /** Full ordinal number in the format [client ordinal number]-[run request ordinal number] */
  fullOrdinalNumber: string
  type: RunRequestTypesEnum
  status: RunRequestStatusEnum
  /** Translated Type */
  translatedType: string
  /** Translated Status */
  translatedStatus: string
  /** Date when run request will be arrived */
  arrivalDate: string
  /** The start time when run request will be arrived */
  arrivalTimeFrom: string
  /** The end time when run request will be arrived */
  arrivalTimeTo: string
  /** Date and time when run request received terminal status (done, cancelled, error, etc) */
  closedAt: string | null
  /** Date and time when run request was created */
  createdAt: string
  /** Date and time when run request was updated */
  updatedAt: string | null
  arrivalMethod: RunRequestArrivalMethodsEnum
  /** Delivery service ID (UUID) */
  deliveryServiceId: string | null
  deliveryService?: ShortDeliveryServiceResource | null
  destinationAddress?: AddressCommonProperties | null
  /** Run request comment */
  comment: string | null
  /** Shipments related to this one (shipments where parentId set to this shipment's id) */
  shipments?: ShortShipmentResource[] | null
  cargoVolume?: RunRequestCargoVolumesEnum
  packageType?: CargoPackageTypes
  /** Number of cargo pallets when the exact cargo is unknown */
  cargoNumberOfPallets?: number
  cargoType?: RunRequestCargoTypesEnum
  /** Files (usually images) attached to this run request */
  attachments?: Attachment[]
  deliveryServiceTrackNumber?: string | null
  /** driver ID (UUID) */
  driverId?: string | null
  driver?: DriverResource | null
  /** vehicle ID (UUID) */
  vehicleId?: string | null
  vehicle?: ShortVehicleResource | null
}
export type ShipmentLogInternal = {
  /** Field describing the action with the author of the changes */
  updateSource?: string
  technicalLog?: string
}
export type ShipmentLog = {
  /** Shipment status */
  status?:
    | 'AT_SENDER_PARSE_ERROR'
    | 'AT_SENDER_NEW'
    | 'AT_SENDER_PACKED'
    | 'AT_SENDER_AWAITING_SELF_PICKUP'
    | 'AT_SENDER_AWAITING_QUBY_PICKUP'
    | 'QUBY_VEHICLE_2_QUBY'
    | 'AT_QUBY_AWAITING_CONFIRMATION'
    | 'AT_QUBY_AWAITING_CORRECTION'
    | 'AT_QUBY_AWAITING_ROUTING'
    | 'AT_QUBY_AWAITING_DELIVERY'
    | 'AGENT_VEHICLE_2_AGENT'
    | 'AT_AGENT'
    | 'AGENT_VEHICLE_2_RECEIVER'
    | 'AGENT_ERROR'
    | 'RETURN_AGENT_VEHICLE_2_QUBY'
    | 'RETURN_AGENT_VEHICLE_2_SENDER'
    | 'AT_AGENT_LOST'
    | 'QUBY_VEHICLE_2_RECEIVER'
    | 'AT_RECEIVER_DONE'
    | 'RETURN_QUBY_VEHICLE_2_QUBY'
    | 'RETURN_AT_QUBY_AWAITING_CONFIRMATION'
    | 'RETURN_AT_QUBY_AWAITING_RETURN'
    | 'RETURN_QUBY_VEHICLE_2_SENDER'
    | 'AT_SENDER_RETURNED'
    | 'AT_SENDER_CANCELLED'
  /** Translated Status */
  translatedStatus?: string
  comment?: string
  /** Field with detailed description of changes and comments */
  message?: string
  /** Field describing the action */
  updateSource?: string
  /** Date and time when log was created */
  createdAt?: string
  internal?: ShipmentLogInternal
}
export type ShortRouteResource = {
  /** Route ID (UUID) */
  id?: string
  /** Route name */
  routeName?: string
}
export type DlvSelectedService = {
  /** Name of a service (provided by dlv) */
  service?: string
  price?: number
  /** Label of a service (provided by dlv) */
  label?: string
}
export type RunRequestInternalResource = {
  /** Order number for yandex monitoring */
  orderNumberFromYandexMonitoring: string | null
  /** Yandex order id */
  yandexOrderId: string | null
  /** Track number for run request in external delivery service */
  deliveryServiceTrackNumber?: string | null
  /** Amount of money quby will pay (or already paid) to the delivery service */
  deliveryCost: number | null
  /** Additional services to be provided (or already provided) by external delivery service */
  dlvSelectedServices: DlvSelectedService[]
  /** Contract ID of delivery service used for this shipment in DLV microservice */
  dlvContractId: string
  /** External yandex routing task id */
  externalYandexRoutingTaskId: string | null
  client?: ShortClientResource
}
export type RunRequestResource = RunRequestCommonProperties & {
  /** Show the menu for printing external stickers */
  isShowPrintExternalStickersMenu?: boolean
  clientWarehouse?: LocationShortResource
  qubyWarehouse?: LocationShortResource
  /** History logs of this shipment */
  logs?: ShipmentLog[]
  route?: ShortRouteResource | null
  /** Flag indicating whether the driver and transport are allowed to be modified */
  canEditDriverAndVehicle?: boolean
  internal?: RunRequestInternalResource
}
export type RouteResource = {
  /** Route ID (UUID) */
  id: string
  /** Route name */
  routeName?: string
  /** Route date */
  routeDate?: string
  /** The time when route starts */
  shiftTimeFrom?: string | null
  /** The time when route ends */
  shiftTimeTo?: string | null
  /** Route cost */
  cost?: number
  /** driver ID (UUID) */
  driverId?: string | null
  driver?: DriverResource | null
  /** vehicle ID (UUID) */
  vehicleId?: string | null
  vehicle?: VehicleResource | null
  /** Delivery service ID (UUID) */
  deliveryServiceId?: string | null
  deliveryService?: ShortDeliveryServiceResource | null
  /** Related run requests */
  runRequests?: RunRequestResource[]
  /** Date and time when route was created */
  createdAt?: string
  /** Date and time when route was updated last time */
  updatedAt?: string
}
export type RouteStoreRequest = {
  /** Дата маршрута */
  routeDate: string
  /** driver ID (UUID) */
  driverId: string
  /** vehicle ID (UUID) */
  vehicleId: string
  /** delivery service ID (UUID) */
  deliveryServiceId: string
  /** The time when route starts */
  shiftTimeFrom?: string | null
  /** The time when route ends */
  shiftTimeTo?: string | null
  /** Cost of the route */
  cost?: number
  /** Full list of related run request ids */
  runRequestIds: string[]
}
export type RouteUpdateRequest = {
  /** driver ID (UUID) */
  driverId?: string
  /** vehicle ID (UUID) */
  vehicleId?: string
  /** delivery service ID (UUID) */
  deliveryServiceId?: string
  /** The time when route starts */
  shiftTimeFrom?: string | null
  /** The time when route ends */
  shiftTimeTo?: string | null
  /** Cost of the route */
  cost?: number
  /** Full list of related run request ids */
  runRequestIds: string[]
}
export type ShortRunRequestResource = RunRequestCommonProperties & {
  /** Show the menu for printing external stickers */
  isShowPrintExternalStickersMenu?: boolean
  /** Amount of all cargo packages of all related shipments */
  cargoPackagesAmount?: number
  internal?: RunRequestInternalResource
}
export type RunRequestOrderByTypeEnum =
  | 'status'
  | 'type'
  | 'ordinalNumber'
  | 'fullOrdinalNumber'
  | 'arrivalDate'
  | 'arrivalMethod'
  | 'updatedDate'
  | 'deliveryServiceId'
export type InternalRunRequestData = {
  /** Amount of money quby will pay (or already paid) to the delivery service */
  deliveryCost?: number
  /** Track number for run request in external delivery service */
  deliveryServiceTrackNumber?: string
  /** Contract ID of delivery service used for this shipment in DLV microservice */
  dlvContractId?: string
  dlvSelectedServices?: DlvSelectedService[]
}
export type RunRequestStoreRequest = {
  type: RunRequestTypesEnum
  arrivalMethod?: RunRequestArrivalMethodsEnum
  /** The date the goods will arrive */
  arrivalDate: string
  /** The start time when the goods will arrive */
  arrivalTimeFrom: string
  /** The end time when the goods will arrive */
  arrivalTimeTo: string
  packageType?: CargoPackageTypes
  cargoVolume?: RunRequestCargoVolumesEnum
  /** Number of cargo pallets (When the contents of the shipment are unknown). not empty when cargoVolume is LARGE */
  cargoNumberOfPallets?: number
  cargoType?: RunRequestCargoTypesEnum
  /** Comment */
  comment?: string | null
  /** Address value as string */
  destinationAddressValue?: string | null
  /** Quby warehouse ID (UUID) */
  qubyWarehouseId?: string | null
  /** Client warehouse ID (UUID) */
  clientWarehouseId?: string | null
  /** Full list of related shipment ids (When the contents of the shipment are known) */
  shipmentIds?: string[]
  /** driver ID (UUID) */
  driverId?: string | null
  /** vehicle ID (UUID) */
  vehicleId?: string | null
  /** delivery service ID (UUID). Set QUBY if not specified */
  deliveryServiceId?: string
  internal?: InternalRunRequestData
}
export type RunRequestUpdateRequest = {
  /** Comment */
  comment?: string | null
  /** The date the goods will arrive */
  arrivalDate?: string
  /** The start time when the goods will arrive */
  arrivalTimeFrom?: string
  /** The end time when the goods will arrive */
  arrivalTimeTo?: string
  arrivalMethod?: RunRequestArrivalMethodsEnum
  /** Full list of related shipment ids (When the contents of the shipment are known) */
  shipmentIds?: string[]
  cargoVolume?: RunRequestCargoVolumesEnum
  packageType?: CargoPackageTypes
  /** Number of cargo pallets (When the contents of the shipment are unknown). not empty when cargoVolume is LARGE */
  cargoNumberOfPallets?: number
  cargoType?: RunRequestCargoTypesEnum
  /** driver ID (UUID) */
  driverId?: string | null
  /** vehicle ID (UUID) */
  vehicleId?: string | null
  /** Quby warehouse ID (UUID). Updates only for PICKUP run requests with arrival method QUBY_CAR) */
  qubyWarehouseId?: string | null
  /** Client warehouse ID (UUID). Updates only for PICKUP run requests with arrival method SELF_DELIVERY */
  clientWarehouseId?: string | null
  /** delivery service ID (UUID). */
  deliveryServiceId?: string
  internal?: InternalRunRequestData
}
export type RunRequestChangeStatusRequest = {
  status: RunRequestStatusEnum
  /** Comment on the reason for the status change for the log */
  comment?: string | null
  /** Closing date of the run request. (Only for DONE and CANCELLED statuses) */
  closedAt?: string | null
}
export type GoodData = BaseGoodData & {
  amount?: number
}
export type GoodReference = {
  /** Id of good in quby database */
  id: string
  /** Amount of goods with provided id */
  amount: number
  /** Price */
  price: number
}
export type CargoPackageData = {
  packageType?: CargoPackageTypes
  length?: number
  width?: number
  height?: number
  weight?: number
  estimatedCost?: number
  barcodeNumber?: string
  /** Array of goods */
  goods?: (GoodData | GoodReference)[]
}
export type InternalCargoPackageData = {
  /** Статус приемки грузового места */
  status?: 'UNPROCESSED' | 'ACCEPTED' | 'DAMAGED' | 'LOST'
}
export type ShipmentAuthCargoPackagesResource = CargoPackageData & {
  internal?: InternalCargoPackageData
}
export type ShipmentInternalResource = {
  /** Should vehicle go directly from sender to receiver location (true), or be unloaded in QubyWarehouse after pickup */
  isDirectRun: boolean
  /** Amount of money quby will pay (or already paid) to the delivery service */
  deliveryCost: number
  /** Track number for shipment in external delivery service */
  deliveryServiceTrackNumber: string
  client?: ShortClientResource
  deliveryService?: ShortDeliveryServiceResource
  /** Additional services to be provided (or already provided) by external delivery service */
  dlvSelectedServices: DlvSelectedService[]
  /** Contract ID of delivery service used for this shipment in DLV microservice */
  dlvContractId: string
  /** Invoice Id */
  invoiceId: string
  /** insuredAt */
  insuredAt: string
  /** isMarkedForCancel */
  isMarkedForCancel: boolean
  /** reasonOfCancel */
  reasonOfCancel: string
}
export type ShipmentAuthResource = {
  /** Seller of this shipment (usually for FBO purposes and cases where quby client aggregates multiple sellers) */
  seller: string
  /** Unique external (from client system) identifier for the shipment */
  externalId: string
  /** Unique identifier of FBO delivery. Could only be provided when one of locations is FBO marketplace */
  fboDeliveryNumber: string
  pickup: PickupData
  delivery: DeliveryData
  /** Array of additional services to be done or already done by quby */
  additionalServices: ShipmentAdditionalServicesEnum[]
  /** Сформировано паллет */
  numberOfPalletsToForm: number
  /** Кол-во паллет к продаже */
  numberOfPalletsToSell: number
  /** Кол-во часов разовых работ */
  hoursOfOneTimeServices: number
  /** Shipment ID (UUID) */
  id: string
  /** Flag indicating if the address type can be edited */
  canEditAddressTypes?: boolean
  /** Array of cargo packages */
  cargoPackages: ShipmentAuthCargoPackagesResource[]
  /** Full ordinal number in the format [client ordinal number]-[shipment ordinal number] */
  fullOrdinalNumber: string
  type: ShipmentTypesEnum
  status: ShipmentStatusEnum
  /** Date and time when shipment change last status */
  changeStatusDate: string
  /** Date and time when shipment received terminal status (returned, delivered, cancelled, etc) */
  closedAt: string | null
  /** Translated Type */
  translatedType: string
  /** Translated Status */
  translatedStatus: string
  /** Date and time when shipment was created */
  createdAt: string
  /** Date and time when shipment was updated last time */
  updatedAt: string
  /** Files (usually images) attached to this shipment */
  attachments: Attachment[]
  /** History logs of this shipment */
  logs: ShipmentLog[]
  /** Shipments related to this one (shipments where parentId set to this shipment's id) */
  relatedShipments: ShipmentAuthResource[]
  /** Run Requests related to this one (run requests this shipment included into) */
  relatedRunRequests: ShortRunRequestResource[]
  internal?: ShipmentInternalResource
}
export type ShipmentOrderByTypeEnum =
  | 'ordinalNumber'
  | 'fullOrdinalNumber'
  | 'status'
  | 'id'
  | 'deliveryServiceId'
  | 'createdDate'
  | 'deliveryDate'
export type ShipmentRelationsEnum = 'runRequests' | 'cargoPackages' | 'logs'
export type ShipmentStoreCargoPackageData = CargoPackageData
export type InternalShipmentData = {
  /** Should vehicle go directly from sender to receiver location (true), or be unloaded in QubyWarehouse after pickup */
  isDirectRun?: boolean
  /** Contract ID of delivery service used for this shipment in DLV microservice */
  dlvContractId?: string
  /** Additional services to be provided (or already provided) by external delivery service */
  dlvSelectedServices?: DlvSelectedService[]
}
export type ShipmentStoreRequest = {
  /** Array of cargo packages */
  cargoPackages: ShipmentStoreCargoPackageData[]
  /** Seller of this shipment (usually for FBO purposes and cases where quby client aggregates multiple sellers) */
  seller?: string
  /** Unique external (from client system) identifier for the shipment */
  externalId?: string
  /** Unique identifier of FBO delivery. Could only be provided when one of locations is FBO marketplace */
  fboDeliveryNumber?: string
  pickup: PickupData
  delivery: DeliveryData
  /** Array of additional services to be done or already done by quby */
  additionalServices: ShipmentAdditionalServicesEnum[]
  /** Сформировано паллет */
  numberOfPalletsToForm?: number
  /** Кол-во паллет к продаже */
  numberOfPalletsToSell?: number
  /** Кол-во часов разовых работ */
  hoursOfOneTimeServices?: number
  /** UUID of another shipment this new one should be related to (ex: you want to create documents return shipment and make it linked to initial products shipment) */
  parentShipmentId?: string
  /** Indicates if the shipment needs Quby warehouse */
  needsQubyWarehouse?: boolean
  internal?: InternalShipmentData
}
export type ShipmentUpdateCargoPackageData = CargoPackageData & {
  id?: string
  internal?: InternalCargoPackageData
}
export type ShipmentUpdateRequest = {
  /** Array of cargo packages */
  cargoPackages: ShipmentUpdateCargoPackageData[]
  /** Seller of this shipment (usually for FBO purposes and cases where quby client aggregates multiple sellers) */
  seller?: string
  /** Unique external (from client system) identifier for the shipment */
  externalId?: string
  /** Unique identifier of FBO delivery. Could only be provided when one of locations is FBO marketplace */
  fboDeliveryNumber?: string
  pickup?: PickupData
  delivery?: DeliveryData
  /** Array of additional services to be done or already done by quby */
  additionalServices?: ShipmentAdditionalServicesEnum[]
  /** Сформировано паллет */
  numberOfPalletsToForm?: number
  /** Кол-во паллет к продаже */
  numberOfPalletsToSell?: number
  /** Кол-во часов разовых работ */
  hoursOfOneTimeServices?: number
  /** UUID of another shipment this new one should be related to (ex: you want to create documents return shipment and make it linked to initial products shipment) */
  parentShipmentId?: string
  /** Indicates if the shipment needs Quby warehouse */
  needsQubyWarehouse?: boolean
  internal?: InternalShipmentData
}
export type ShipmentChangeStatusRequest = {
  status: ShipmentStatusEnum
  /** Comment on the reason for the status change for the log */
  comment: string
}
export type RunRequestData = {
  date: string
  timeFrom: string
  timeTo: string
  /** Run request comment */
  comment?: string | null
  /** Address value as string */
  destinationAddressValue: string | null
  /** delivery service ID (UUID). Set QUBY if not specified */
  deliveryServiceId?: string | null
  /** driver ID (UUID) */
  driverId?: string | null
  /** vehicle ID (UUID) */
  vehicleId?: string | null
}
export type ShipmentCreateRunRequestsRequest = {
  pickup?: RunRequestData
  delivery?: RunRequestData
}
export type ShipmentsRegisterExportColumnsEnum =
  | 'INNER_NUMBER'
  | 'EXTERNAL_ID'
  | 'PARTNER_NAME'
  | 'SENDER'
  | 'RECEIVER'
  | 'STATUS'
  | 'TYPE'
  | 'CREATED_DATE'
  | 'INSURANCE_SUM'
  | 'CARGO_PACKAGES_TYPE'
  | 'CARGO_PACKAGES_AMOUNT'
  | 'WEIGHT'
  | 'VOLUME_WEIGHT'
  | 'ACTUAL_PICKUP_TIME'
  | 'ACTUAL_DELIVERY_TIME'
  | 'INVOICE'
  | 'DELIVERY_SERVICE'
  | 'REASON_OF_CANCEL'
  | 'DESIRED_PICKUP_INTERVAL'
  | 'PLANNED_PICKUP_INTERVAL'
  | 'DESIRED_DELIVERY_INTERVAL'
  | 'PLANNED_DELIVERY_INTERVAL'
  | 'GOODS'
  | 'RELATED_RUN_REQUESTS'
  | 'DELIVERY_COST'
  | 'DELIVERY_SERVICE_TRACK_NUMBER'
  | 'SHIPMENT_COMMENT'
  | 'DRIVER'
  | 'TOTAL_VOLUME'
  | 'PALLET_COUNT'
export type BaseStatisticsFiltersResource = {
  deliveredFrom: string
  deliveredTo: string
}
export type StatisticsDeliveryServicesTabsEnum = 'partners' | 'quby'
export type StatisticsDeliveryServicesFiltersResource =
  BaseStatisticsFiltersResource & {
    /** Array of delivery service ids  */
    deliveryServiceIds?: string[]
    selectedTab?: StatisticsDeliveryServicesTabsEnum
  }
export type StatisticsChartTypeEnum = 'Line' | 'Bar'
export type BaseStatisticsTooltipsResource = {
  /** Название партнёра */
  title: string
  value: number
}
export type BaseStatisticsProductResource = {
  /** Число для отображения на графике */
  value: number
  tooltips: BaseStatisticsTooltipsResource[]
}
export type BaseStatisticsValuesResource = {
  /** Name of month with year */
  name: string
  product1: BaseStatisticsProductResource
  product2?: BaseStatisticsProductResource
  product3?: BaseStatisticsProductResource
}
export type BaseStatisticsLabelsLegend = {
  product1: string
  product2?: string
  product3?: string
}
export type BaseStatisticDataResource = {
  alias: string
  type: StatisticsChartTypeEnum
  label: string
  values: BaseStatisticsValuesResource[]
  labelsLegend: BaseStatisticsLabelsLegend
}
export type StatisticsRevenueTabsEnum = 'revenue' | 'weight'
export type StatisticsRevenueFiltersResource = BaseStatisticsFiltersResource & {
  /** Array of client ids  */
  clientIds?: string[]
  selectedTab?: StatisticsRevenueTabsEnum
}
export type DataForCalculatorGetTariffsSenderLocationResource = {
  area: string
  city: string
  cityArea: string
  cityFiasId: string
  country: string
  fiasId: string
  region: string
  regionFiasId: string
  settlement: string
  settlementFiasId: string
  zipcode: string
}
export type DataForCalculatorGetTariffsReceiverLocationResource = {
  area: string
  city: string
  cityArea: string
  cityFiasId: string
  country: string
  fiasId: string
  region: string
  regionFiasId: string
  settlement: string
  settlementFiasId: string
  zipcode: string
}
export type DataForCalculatorGetTariffsResource = {
  /** Shipment type */
  shipmentType:
    | 'TO_THE_DOOR'
    | 'FROM_THE_DOOR'
    | 'DOOR_TO_DOOR'
    | 'DOOR_WAREHOUSE_DOOR'
    | 'FBO'
    | 'REVERSE_FBO'
    | 'DOCUMENTS_RETURN'
  /** senderLocation */
  senderLocation: DataForCalculatorGetTariffsSenderLocationResource[]
  /** senderLocation */
  receiverLocation: DataForCalculatorGetTariffsReceiverLocationResource[]
}
export type VehicleCapacityVolumeRequest = {
  /** height */
  height?: number
  /** width */
  width?: number
  /** depth */
  depth?: number
}
export type VehicleCapacityRequest = {
  /** weight */
  weight?: number
  volume?: VehicleCapacityVolumeRequest
}
export type VehicleStoreRequest = {
  /** brand */
  brand: string
  /** plateNumber */
  plateNumber: string
  /** description */
  description?: string
  capacity?: VehicleCapacityRequest
  /** numberSts */
  numberSts?: string
  /** numberVin */
  numberVin?: string
  /** Активный? */
  isActive?: boolean
}
export type VehicleUpdateRequest = VehicleStoreRequest
