import React, { FC } from 'react'
import { motion as m } from 'framer-motion'
import Cross from '@/components/Icons/Cross'

interface Porps {
  onClear: () => void
}

const CrossIndicator: FC<Porps> = ({ onClear }) => {
  return (
    <m.div onClick={onClear}>
      <Cross />
    </m.div>
  )
}

export default CrossIndicator
