import { DlvError, qubyApiDlv, ShipmentRequest } from './generated/qubyApiDlv'
import { PaginationResponse } from './generated/qubyApiPhp'

const convertParamMonitoring = (arg: GetMonitoringApiArg) => {
  if (arg.query) {
    return {
      num: arg.query,
    }
  }

  return {
    dateFrom: arg.dateFrom,
    dateTo: arg.dateTo,
    dlvStatuses:
      //@ts-ignore
      arg.dlvStatuses?.length ? arg.dlvStatuses.join(',') : arg.dlvStatuses,
    courierStatuses:
      arg.courierStatuses?.length ?
        //@ts-ignore
        arg.courierStatuses.join(',')
      : arg.courierStatuses,

    page: arg.currentPage ? arg.currentPage - 1 : 0,
    size: arg.perPage,
    statusDuration: arg.statusDuration,
    filterType: arg.filterType,
    warehouse: arg.warehouse,
    updating: arg.updating,
  }
}
const envVariables = require('@/constants/env')
export const qubyApiEgoDlv = qubyApiDlv.injectEndpoints({
  endpoints: (build) => ({
    getMonitoringList: build.query<
      GetMonitoringApiResponse,
      GetMonitoringApiArg
    >({
      query: (queryArg) => {
        const originalParams = convertParamMonitoring(queryArg)
        return {
          url: `https://dlv-app.kak2c.ru/api/monitoring/order${!queryArg.query ? 's' : ''}-info`,
          method: 'GET',
          params: {
            ...originalParams,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),
    getMonitoringById: build.query<
      GetMonitoringByIdApiResponse,
      GetMonitoringByIdApiArg
    >({
      query: (queryArg) => {
        return {
          url: `https://dlv-app.kak2c.ru/api/monitoring/order-info`,
          method: 'GET',
          params: {
            dlvId: queryArg?.dlvId,
            updating: queryArg?.updating,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),
    getMonitoringUploadingToCsById: build.mutation<
      PostMonitoringUploadingToCsByIdApiResponse,
      PostMonitoringUploadingToCsByIdApiArg
    >({
      query: (queryArg) => {
        return {
          url: `https://dlv-app.kak2c.ru/api/commitShipmentRequestList`,
          method: 'POST',
          body: {
            dlvIdList: queryArg?.dlvIdList,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      invalidatesTags: [],
    }),
    getPlanReturnsList: build.query<
      GetPlanReturns2ApiResponse,
      GetPlanReturns2ApiArgEgo
    >({
      query: (queryArg) => ({
        url: `https://dlv-app.kak2c.ru/api/returns/planReturnsAll`,
        method: 'GET',
        params: {
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          docNumber: queryArg.query || undefined,
          warehouse: queryArg.warehouse,
          courierCode: queryArg.courierCode,
        },
        headers: {
          Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
        },
      }),
      providesTags: ['returns-controller'],
    }),
    getShipmentDlvById: build.query<
      GetShipmentDlvGetByIdResponse,
      GetShipmentDlvGetByIdArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.DLV_API_URL}api/v2/shipment/${queryArg?.id}`,
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),
    postShipmentDlv: build.mutation<
      PostShipmentDlvByIdResponse,
      PostShipmentDlvApiArg
    >({
      query: (queryArg) => ({
        url: `${envVariables.DLV_API_URL}api/v2/shipment`,
        method: 'POST',
        body: queryArg.shipmentUpdateRequest,
        headers: {
          Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
        },
      }),
      invalidatesTags: [],
    }),
    getShipmentsDlvList: build.query<
      GetShipmentsDlvListApiResponse,
      GetShipmentDlvApiArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.DLV_API_URL}api/shipments`,
          params: {
            page: queryArg.currentPage ? queryArg.currentPage - 1 : 0,
            size: queryArg.perPage,
            sort: queryArg.sort,
            num: queryArg.num || queryArg.query || undefined,
            status: queryArg.status,
            syncState: queryArg.syncState,
            courierNum: queryArg.courierNum,
            barcode: queryArg.barcode,
            dlvId: queryArg.dlvId,
            courierCode: queryArg.courierCode,
            id: queryArg.id,
            dateFrom:
              queryArg.dateFrom ?
                `${queryArg.dateFrom}T00:00:00`
              : queryArg.dateFrom,
            dateTo:
              queryArg.dateTo ? `${queryArg.dateTo}T00:00:00` : queryArg.dateTo,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),

    getMonitoringLog: build.query<
      GetMonitoringLogApiResponse,
      GetMonitoringLogApiArg
    >({
      query: (queryArg) => {
        return {
          url: `https://dlv-app.kak2c.ru/api/monitoring/exchange`,
          method: 'GET',
          params: {
            type: queryArg.type,
            id: queryArg.id,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),
    getPlacesList: build.query<GetPlacesApiResponse, GetPlacesApiArg>({
      query: (queryArg) => {
        return {
          url: `${envVariables.DLV_API_URL}api/v2/places/${queryArg?.id}`,
          params: {
            page: queryArg.currentPage ? queryArg.currentPage - 1 : 0,
            size: queryArg.perPage,
            sort: queryArg.sort,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),
    getHistoriesList: build.query<GetHistoriesApiResponse, GetHistoriesApiArg>({
      query: (queryArg) => {
        return {
          url: `${envVariables.DLV_API_URL}api/v2/histories/${queryArg?.id}`,
          params: {
            size: queryArg.size,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),
    getCourierCompanies: build.query<
      GetCourierCompaniesApiResponse,
      GetCourierCompaniesApiArg
    >({
      query: (queryArg) => {
        return {
          url: `/api/v2/couriers-info`,
          method: 'GET',
          params: {
            active: queryArg.active,
            marketplace: queryArg.marketplace,
          },
          headers: {
            Authorization: `Bearer${'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX25hbWUiOiJhZG1pbiIsImF1dGhvcml0aWVzIjpbIkFETUlOIl0sImp0aSI6ImIwQTFGRTM0X1pkbkpKTFpqa3dYa0M2MWJ6SSIsImNsaWVudF9pZCI6IkxvY2FsIiwic2NvcGUiOltdfQ.AnxsqYdftchA4AXOOsuoLIOUURlZZo_NWYlK3542VxIFT16Zc4Tod0a4AVMcQjxguZJzHJQ3MQQtKszm7pNoKzmn4MLipvLZXW2TnS5zIfU7qWC5BZc2thnFkw3n-r4AbjqdSWkdv_1X1l6YI6EOF8pAzVzL1R_y-26vJImz2cOVBeU435g4c5KNgGTyUEvXvGPnp_vD_pnTKLOZ5UcgjRWUAA9HHx3xLNgmxIz10WSYNHauCHCBtZYU3p8fwX_CV4ZRT8myyx7cXOnGkesSKHiKfmMmBUjNgWwLHSXG84jJLn3aTVit4CaWsjQ9Hz6AE0jYwro8-aQkMjocRiBLUA'}`,
          },
        }
      },
      providesTags: [],
    }),
  }),
  overrideExisting: true,
})
export type PostMonitoringUploadingToCsByIdApiArg = {
  dlvIdList?: string[]
}
export type PostMonitoringUploadingToCsByIdApiResponse = {
  success: boolean
  errors: ErrorsUploadingToCsResponse[]
  dlvId: string
  courierNum: string | null
  placeList: PlaceListResponse[]
}
export type PlaceListResponse = {
  placeId: string
  barcode: string
}
export type ErrorsUploadingToCsResponse = {
  code: MonitoringErrorType
  message: string
}
export type GetShipmentDlvApiArg = {
  currentPage?: number
  perPage?: number
  sort?: string
  num?: string
  status?: string
  syncState?: string
  courierNum?: string
  createTsFrom?: string
  createTsTo?: string
  barcode?: string
  dlvId?: string
  courierCode?: string
  id?: string
  dateFrom?: string
  dateTo?: string
  query?: string
}
export type GetShipmentDlvGetByIdArg = {
  id: string
}
export type GetShipmentDlvGetByIdResponse = {
  errors?: DlvError[]
  success?: boolean
  entity?: ShipmentRequestEgo
}
export type PostShipmentDlvByIdResponse = {
  errors?: DlvError[]
  success?: boolean
  entity?: ShipmentRequestEgo
}
export type PostShipmentDlvApiArg = {
  shipmentUpdateRequest: ShipmentRequestEgo
}
export type PlacesDlv = {
  barcode?: string
}
export type SyncRecordDlv = {
  num?: string
  status?: string
  syncState?: string
  courierNum?: string
  createTsFrom?: string
  createTsTo?: string
}

export type ShipmentsDlvStatusEnum =
  | 'ACCEPTED'
  | 'UPDATING'
  | 'CANCELING'
  | 'ABORTED'
  | 'UPDATING_SUSPENDED'
export type DlvStatusEnum =
  | 'SENDER'
  | 'ACCEPTED_BY_COURIER'
  | 'COURIER'
  | 'SORT'
  | 'COURIER2'
  | 'PROBLEM'
  | 'DELIVERED'
  | 'RETURNED'
  | 'AT_TAKEOUT_POINT'
  | 'RETURNING'
  | 'LOST'
  | 'PARTIALLY_DELIVERED'
  | 'CANCELED'

export type DeliveryDlvEnum =
  | 'A1'
  | 'BAIKAL'
  | 'Boxberry'
  | 'CDEK'
  | 'CDEKV2'
  | 'Cainiao'
  | 'CityExpress'
  | 'CSE'
  | 'DalliService'
  | 'Dellin'
  | 'DHL'
  | 'DOSTAVISTA'
  | 'DPD'
  | 'DPD_LATVIA'
  | 'EBULKY'
  | 'GD'
  | 'Goods'
  | 'Grastin'
  | 'HALVA'
  | 'HALVAV2'
  | 'Hermes'
  | 'IML'
  | 'Kak2cCourier'
  | 'Kaspi'
  | 'Kholodilnik'
  | 'Lamoda'
  | 'LeroyMerlin'
  | 'Logsis'
  | 'MaxiPost'
  | 'Novaposhta'
  | 'OZON'
  | 'OZON_DELIVERY'
  | 'PbmLatvia'
  | 'PEK'
  | 'PickPoint'
  | 'PickUp'
  | 'PODRYGKA_CDEK'
  | 'PODRYGKA_CSE'
  | 'PonyExpress'
  | 'QUBY'
  | 'RussianPost'
  | 'RussianPostApi'
  | 'RussianPostApiEcom'
  | 'SelfPickup'
  | 'SPSR'
  | 'STRIZH'
  | 'TEMPOLINE_RUSSIAN_POST'
  | 'UPS_Latvia'
  | 'Wildberries'
  | 'X5_Post'
  | 'YANDEX_MARKET'
  | 'YandexDelivery'
  | 'YandexDeliveryV2'
  | 'YandexDeliveryV3'

export type DeliveryDlvReturnFiltersEnum =
  | 'CDEKV2'
  | 'Logsis'
  | 'DalliService'
  | 'Boxberry'
  | 'YANDEX_MARKET'
  | 'Goods'
  | 'OZON'
export type DeliveryDlvReturnFileEnum =
  | 'CDEKV2'
  | 'DalliService'
  | 'Boxberry'
  | 'Goods'

export type GetMonitoringApiArg = {
  statusDuration?: string
  dlvStatuses?: MonitoringDlvStatus[] | string
  courierStatuses?: MonitoringStatus[] | string
  filterType?: string
  num?: string
  currentPage?: number
  perPage?: number
  query?: string
  dateFrom?: string
  dateTo?: string
  year?: number
  month?: string | number
  week?: string | number
  day?: string | number
  hour?: string | number
  minute?: string | number
  second?: string | number
  updating?: string
  warehouse?: string
}
export type GetMonitoringByIdApiArg = {
  query?: string
  dlvId?: string
  updating?: string
}
export type GetMonitoringApiResponse = {
  content: MonitoringContent[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  totalElements: number
  totalPages: number
  pageable: Pageable
  sort: MonitoringContentSort
}
export type GetMonitoringByIdApiResponse = {
  content: MonitoringContent[]
}
export type GetCourierCompaniesApiArg = {
  active?: boolean
  marketplace?: boolean
}
export type GetCourierCompaniesApiResponse = CourierCompanyResponse
export type CourierCompanyResponse = {
  success?: boolean
  errors?: {
    [key: string]: string
  }
  courierCompanies?: CourierCompanyDto[]
}
export type CourierCompanyDto = {
  id?: string
  /** Возможные коды курьерских служб */
  code?:
    | 'PickPoint'
    | 'SPSR'
    | 'CityExpress'
    | 'IML'
    | 'Hermes'
    | 'CDEK'
    | 'CDEKV2'
    | 'Grastin'
    | 'Boxberry'
    | 'GD'
    | 'DPD'
    | 'DPD_LATVIA'
    | 'MaxiPost'
    | 'PickUp'
    | 'PonyExpress'
    | 'YandexDelivery'
    | 'YandexDeliveryV2'
    | 'CSE'
    | 'DOSTAVISTA'
    | 'PEK'
    | 'RussianPost'
    | 'TEMPOLINE_RUSSIAN_POST'
    | 'STRIZH'
    | 'SelfPickup'
    | 'Logsis'
    | 'A1'
    | 'RussianPostApiEcom'
    | 'OZON'
    | 'OZON_DELIVERY'
    | 'DalliService'
    | 'PbmLatvia'
    | 'RussianPostApi'
    | 'Goods'
    | 'Dellin'
    | 'Novaposhta'
    | 'Kak2cCourier'
    | 'UPS_Latvia'
    | 'Wildberries'
    | 'X5_Post'
    | 'Kaspi'
    | 'DHL'
    | 'Cainiao'
    | 'YandexDeliveryV3'
    | 'LeroyMerlin'
    | 'Lamoda'
    | 'Kholodilnik'
    | 'YANDEX_MARKET'
    | 'QUBY'
    | 'PODRYGKA_CDEK'
    | 'PODRYGKA_CSE'
    | 'EBULKY'
    | 'HALVA'
    | 'HALVAV2'
    | 'BAIKAL'
  name?: string
  apiUrl?: string
  testApiUrl?: string
  courierSettings?: CourierSettingsDto
  products?: CourierProductDto[]
  useProductionCalendar?: boolean
  legalName?: string
  inn?: string
  isActive?: boolean
}
export type CourierProductDto = {
  name?: string
  code?: string
  international?: boolean
  docReturn?: boolean
  pvzProduct?: boolean
  maxWeight?: number
  paymentType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
}
export type CourierSettingsDto = {
  oversizedMaxWeight?: number
  oversizedMaxDimensionSum?: number
  oversizedMaxLengthSide?: number
}
export type MonitoringContent = {
  courierCode: string
  courierExchangeLog: DlvExchangeLog
  courierNum: string | null
  dlvId: string
  dlvStatus: MonitoringDlvStatus
  num: string
  status: MonitoringStatus
  dlvExchangeLog: DlvExchangeLog
  warehouseName?: string
  commitError?: string
  createTs?: string
}
export type DlvExchangeLog = {
  exchangeLogs: ExchangeLogs[]
}
export type MonitoringWarehouse = 'Внуково' | 'Коледино'
export type ExchangeLogs = {
  id: string
  login: string
  method: string
  methodType: string
  requestDate: string
  responseCode: string
  url: string
}
export type Pageable = {
  sort: PageableSort
  offset: number
  pageNumber: number
  pageSize: number
  paged: boolean
  unpaged: boolean
}
export type PageableSort = {
  empty: boolean
  unsorted: boolean
  sorted: boolean
}
export type MonitoringContentSort = {
  empty: boolean
  unsorted: boolean
  sorted: boolean
}

export type MonitoringContextType = {
  data: MonitoringContent[]
  meta: PaginationResponse
}
export type MonitoringStatus =
  | 'SENDER'
  | 'ACCEPTED_BY_COURIER'
  | 'COURIER'
  | 'SORT'
  | 'COURIER2'
  | 'PROBLEM'
  | 'AT_TAKEOUT_POINT'
  | 'RETURNING'
  | 'LOST'
  | 'PARTIALLY_DELIVERED'
export type MonitoringStatusFull =
  | 'SENDER'
  | 'ACCEPTED_BY_COURIER'
  | 'COURIER'
  | 'SORT'
  | 'COURIER2'
  | 'PROBLEM'
  | 'AT_TAKEOUT_POINT'
  | 'RETURNING'
  | 'LOST'
  | 'PARTIALLY_DELIVERED'
  | 'DELIVERED'
  | 'RETURNED'
  | 'CANCELED'
  | 'ACCEPTED'
  | 'UPDATING'

export type MonitoringDlvStatus =
  | 'ACCEPTED'
  | 'UPDATING'
  | 'CANCELING'
  | 'UPDATING_SUSPENDED'
export type MonitoringDlvStatusFull =
  | 'ACCEPTED'
  | 'UPDATING'
  | 'CANCELING'
  | 'UPDATING_SUSPENDED'
  | 'ABORTED'
export type MonitoringLogType = 'COURIER' | 'SHIPMENT'

export type MonitoringErrorType =
  | 'COMMIT_ERROR'
  | 'INACTION'
  | 'DEFAULT'
  | 'ALL_ERRORS'
  | 'WITHOUT_COMMIT'
export type MonitoringErrorTypeWithoutDefault =
  | 'INACTION'
  | 'DEFAULT'
  | 'WITHOUT_COMMIT'

export type GetMonitoringLogApiArg = {
  id: string
  type: MonitoringLogType
}
export type GetMonitoringLogApiResponse = {
  url: string
  request: string
  response: string
  responseCode: string
  login: string
}
export type GetPlanReturns2ApiArgEgo = {
  dateFrom?: string
  dateTo?: string
  docNumber?: string
  query?: string
  warehouse?: string
  courierCode?: string
}
export type GetPlanReturns2ApiResponse = /** status 200 OK */ ReturnsDocsDto
export type ReturnsDocsDto = {
  success?: boolean
  errors?: DlvError[]
  ReturnsDocs?: ReturnDocDto[]
}
export type ReturnDocDto = {
  CourierCode?: DeliveryDlvEnum
  ConsDocDate?: string
  DocNumber?: string
  DocDate?: string
  Shipments?: ReturnDocDtoShipments[]
  Elements: Elements[]
  FileUrl?: string | null
}
export type ReturnDocDtoShipments = {
  DlvId?: string
  Num?: string
  CourierNum?: string
  Client?: string
  Warehouse?: string
  Places?: PlaceDto[]
}
export type PlaceDto = {
  Barcode?: string
  PlaceId?: string
  ShipmentId?: string
  SubPieces?: SubPiecesItem[]
}
export type SubPiecesItem = {
  Barcode?: string
  Dame?: string
  ExternalId?: string
  Qty?: string
  VendorCode?: string
}
export type Elements = {
  description: string
  Qty: number | null
  ExternalId: string
  AdditionalCourierId: string | null
  Num: string
  CourierNum: string | null
  Warehouse: string | null
  Price: number | null
  ShipmentInfo: ShipmentInfo | null
  DlvPlaceInfo: DlvPlaceInfo | null
  DlvSubPieceInfo: DlvSubPieceInfo | null
}
export type DlvSubPieceInfo = {
  Description: string
  InternationalDescription: string
  Price: number
  VatSum: number
  VatType: string
  TnvedCode: string
  ExternalID: string
  VendorCode: string
  MarketPlaceCode: string
  Received: boolean
  ReceivedQuantity: number
  Barcode: string
  CountryOfOrigin: string
  Weight: number
  MarketplaceSkuId: string
  ControlIdMarks: string
  CustomData: string
}
export type DlvPlaceInfo = {
  PlaceId: string
  ShipmentId: string
  Barcode: string
}
export type ShipmentInfo = {
  DlvId: string
  Num: string
  CourierNum: string
  Client: string
  Warehouse: string
}
export type ReturnsDocsDtoEgo = {
  CourierCode?: DeliveryDlvEnum
  ConsDocNum?: string
  ConsDocDate?: string
  DocNumber?: string
  DocDate?: string
  DlvId?: string
  Num?: string
  CourierNum?: string
  Warehouse?: string
  PlaceBarcode?: string
  Client?: string
  PlaceId?: string
  ShipmentId?: string
  Barcode?: string
  Dame?: string
  ExternalId?: string
  Qty?: string
  VendorCode?: string
}
export type ReturnsDocsConvertType = {
  CourierCode?: DeliveryDlvEnum
  ConsDocDate?: string
  DocNumber?: string
  DocDate?: string
  Warehouse?: string | null
  ShipmentWarehouse?: string | null
  ShipmentNum?: string | null
  ShipmentCourierNum?: string | null
  Client?: string
  PlaceBarcode?: string
  Barcode?: string
  Price?: number
  Description?: string
  VendorCode?: string
  Error?: boolean
  ExternalId?: string
  FileUrl?: string | null
}
export type CashOnDeliveryType = 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE'
export type DeliveryShipmentType = 'FAST' | 'CHEAP' | 'CONTRACT' | 'COURIER'
export type DeliveryVATType =
  | 'VAT18'
  | 'VAT20'
  | 'VAT10'
  | 'VAT_CALC_20_120'
  | 'VAT_CALC_18_118'
  | 'VAT_CALC_10_110'
  | 'VAT0'
  | 'NO_VAT'
  | 'VATX'
export type LiteShipment = 'LITE_SHIPMENT_1' | 'LITE_SHIPMENT_2'
export type PayerType = 'SENDER' | 'RECEIVER'
export type CourierServices =
  | 'TRYING_PRICE'
  | 'PARTIAL_DELIVERY_PRICE'
  | 'CHECK_CONTENT_PRICE'
  | 'PERSONALLY_IN_HANDS_PRICE'
  | 'VERIFY_PRICE'
  | 'REVERSE_PRICE'
  | 'DAY_OFF_DELIVERY_PRICE'
  | 'LIFT_TO_DOOR_PRICE'
  | 'LIFT_TO_DOOR_MANUAL_PRICE'
  | 'BOX_NEEDED_PRICE'
  | 'TRYING'
  | 'PARTIAL_DELIVERY'
  | 'CHECK_CONTENT'
  | 'PERSONALLY_IN_HANDS'
  | 'VERIFY'
  | 'REVERSE'
  | 'DAY_OFF_DELIVERY'
  | 'LIFT_TO_DOOR'
  | 'LIFT_TO_DOOR_MANUAL'
  | 'BOX_NEEDED'
  | 'WAITING_ON_ADDRESS'
  | 'TEMPERATURE_REGIME'
  | 'DELIVERY_DATE_APPROVE'
  | 'CONTACTLESS_DELIVERY'
  | 'DANGEROUS_CARGO'
export type SyncStateStatus =
  | 'SENDER'
  | 'ACCEPTED_BY_COURIER'
  | 'COURIER'
  | 'SORT'
  | 'COURIER2'
  | 'PROBLEM'
  | 'DELIVERED'
  | 'RETURNED'
  | 'AT_TAKEOUT_POINT'
  | 'RETURNING'
  | 'LOST'
  | 'PARTIALLY_DELIVERED'
  | 'CANCELED'
export type CancellationStatus =
  | 'ERROR'
  | 'IN_PROCESS'
  | 'CANCELLED'
  | 'NOT_CANCELLED'

export type GetPlacesApiArg = {
  currentPage?: number
  perPage?: number
  sort?: string
  id: string
}
export type GetPlacesApiResponse = /** status 200 OK */ PlacesDocs
export type PlacesDocs = {
  success?: boolean
  errors?: DlvError[]
  list?: PlacesList[]
  pagination?: {
    totalElements?: number
    currentPage?: number
    totalPages?: number
  }
}
export type PlacesList = {
  barcode?: string | null
  description?: string | null
  dimension?: {
    depth?: number | null
    height?: number | null
    weight?: number | null
    width?: number | null
  } | null
  externalId?: string | null
  giveToCourierDate?: string | null
  id: string | null
  index?: string | null
  litePacked?: boolean | null
  lpNumber?: number | null
  solutionCode?: string | null
  stickerPdf?: string[] | null
  stickerValue?: string | null
  stickerZpl?: string | null
  subPieces: PlacesSubPieces[]
}
export type PlacesSubPieces = {
  barcode?: string | null
  boxNeeded?: boolean | null
  controlIdMarks?: string | null
  controlIdMarksMask?: string | null
  countryOfOrigin?: string | null
  createTs?: string | null
  customData?: SubPiecesCustomData[] | null
  description?: string | null
  externalId?: string | null
  id: string | null
  index?: number | null
  internationalDescription?: string | null
  jwUin?: string | null
  marketPlaceCode?: string | null
  marketplaceSkuId?: string | null
  price?: number | null
  priceNoVat?: number | null
  quantity?: number | null
  received?: boolean | null
  receivedQuantity?: number | null
  skuCode?: string | null
  summaryPrice?: number | null
  summaryPriceNoVat?: number | null
  summaryVatPrice?: number | null
  tnvedCode?: string | null
  updateTs?: string | null
  vatPrice?: number | null
  vatSum?: number | null
  vatType?: DeliveryVATType | null
  vendorCode?: string | null
  weight?: number | null
}
export type SubPiecesCustomData = {
  paramName?: string | null
  paramValue?: string | null
}
export type GetHistoriesApiArg = {
  size?: number
  id: string
}
export type GetHistoriesApiResponse = {
  success?: boolean
  errors?: DlvError[]
  list?: HistoriesType[]
  pagination?: {
    totalElements?: number
    currentPage?: number
    totalPages?: number
  }
}
export type HistoriesType = {
  cancelReason?: string | null
  city?: string | null
  createTs?: string | null
  dateTime?: string | null
  dlvId?: string | null
  index?: number | null
  message?: string | null
  status?: string | null
}
export type ShipmentRequestEgo = {
  id?: string | null
  syncRecord?: ShipmentRequestSyncEgo | null
  sender?: CustomerEgo | null
  senderAddress?: AddressEgo | null
  receiverAddress?: AddressEgo | null
  supplierInfo?: SupplierInfoEgo | null
  yandexParams?: YandexParamsEgo | null
  cashOnDeliveryType?: CashOnDeliveryType | null
  description?: string | null
  comment?: string | null
  docReturn?: boolean | null
  byHand?: boolean | null
  deliveryVATType?: DeliveryVATType | null
  deliveryType?: DeliveryShipmentType | null
  startPickUp?: string | null
  endPickUp?: string | null
  factShipmentDate?: string | null
  estimatedDeliveryDate?: string | null
  insuranceSum?: number | null
  deliveryPrice?: number | null
  cashOnDelivery?: number | null
  freeDeliveryFromSum?: number | null
  baseTariffPrice?: number | null
  baseTariffDiscountPrice?: number | null
  contractId?: string | null
  /** Возможные коды курьерских служб */
  courierCode?: DeliveryDlvEnum | null
  /** Возможные коды курьерских служб */
  courierCode2?: DeliveryDlvEnum | null
  litePacked?: boolean | null
  courierProduct?: string | null
  courierProductChangeHistory?: string | null
  postamatNum?: string | null
  postamatNumChangeHistory?: string | null
  codParams?: CodParamsEgo | null
  registerNum?: string | null
  additionalInfo?: string | null
  places?: PlaceEgo[] | null
  pallets?: PalletEgo[] | null
  serviceParams?: ServiceParamEgo[] | null
  planDeliveryDate?: string | null
  planDeliveryDateChangeHistory?: string | null
  planDeliveryTimeStart?: string | null
  planDeliveryTimeEnd?: string | null
  planDeliveryDateChangeReason?: string | null
  cargoPickUpDate?: string | null
  primaryContractId?: string | null
  liteShipment?: 'LITE_SHIPMENT_1' | 'LITE_SHIPMENT_2' | null
  prevShipment?: string | null
  payerType?: 'SENDER' | 'RECEIVER' | null
  floor?: number | null
  customData?: {
    [key: string]: string
  } | null
  hourOfDeliveryTimeStart?: string | null
  hourOfDeliveryTimeEnd?: string | null
  servicesWithPrices?: {
    [key: string]: number
  } | null
  receiverAll?: CustomerEgo | null
  paymentType?: 'CASH' | 'CARD' | 'CASH_AND_CARD' | 'NONE' | null
  megamarketData?: MegamarketDataDtoEgo | null
  placesDimension?: DimensionEgo[] | null
}
export type ShipmentRequestSyncEgo = {
  id?: string | null
  createTs?: string | null
  updateTs?: string | null
  num?: string | null
  courierNum?: string | null
  tempCourierNum?: string | null
  postalApiId?: string | null
  parcelNum?: string | null
  returningCourierNum?: string | null

  syncState?:
    | 'ACCEPTED'
    | 'UPDATING'
    | 'CANCELING'
    | 'ABORTED'
    | 'UPDATING_SUSPENDED'
    | null
  syncMessage?: string | null
  cancelReason?: string | null
  /** Статус заказа. Движение заказа у курьерской службы */
  status?:
    | 'SENDER'
    | 'ACCEPTED_BY_COURIER'
    | 'COURIER'
    | 'SORT'
    | 'COURIER2'
    | 'PROBLEM'
    | 'DELIVERED'
    | 'RETURNED'
    | 'AT_TAKEOUT_POINT'
    | 'RETURNING'
    | 'LOST'
    | 'PARTIALLY_DELIVERED'
    | 'CANCELED'
    | null
  /** Статус отмены заказа. Возможные значения:

    ERROR - Произошла ошибка отмены,

    IN_PROCESS - Происходит обработка в КС,

    CANCELLED - Успешно оменено,

    NOT_CANCELLED - Стандартный статус. Запроса на отмену не было
     */
  cancellationStatus?:
    | 'ERROR'
    | 'IN_PROCESS'
    | 'CANCELLED'
    | 'NOT_CANCELLED'
    | null
  cancellationStatusTs?: string | null
  cancellationStateError?: string | null
  currentCity?: string | null
  lastRequestTs?: string | null
  registeredTs?: string | null
  docReturn?: boolean | null
  statusDate?: string | null
  dlvLogin?: string | null
  actualDeliveryDate?: string | null
  courierData?: string | null
  registerActId?: string | null
  registeredActTs?: string | null
  commitTry?: boolean | null
  idAsString?: string | null
}
export type CustomerEgo = {
  name?: string | null
  company?: string | null
  phone?: string | null
  phone2?: string | null
  email?: string | null
  inn?: string | null
  warehouse?: string | null
  firstName?: string | null
  lastName?: string | null
  middleName?: string | null
}
export type AddressEgo = {
  zipcode?: string | null
  country?: string | null
  region?: string | null
  city?: string | null
  area?: string | null
  settlement?: string | null
  street?: string | null
  house?: string | null
  block?: string | null
  building?: string | null
  flat?: string | null
  replacement?: string | null
  fiasCode?: string | null
  kladrCode?: string | null
  cityFiasCode?: string | null
  regionFiasCode?: string | null
  areaFiasCode?: string | null
  settlementFiasCode?: string | null
}
export type SupplierInfoEgo = {
  supplierINN?: string | null
  supplierPhone?: string | null
}
export type YandexParamsEgo = {
  warehouseFromId?: string | null
  requisiteId?: string | null
  senderId?: string | null
  intervalId?: string | null
  deliveryName?: string | null
  direction?: string | null
  tariffId?: string | null
  pickuppoint?: string | null
  ydSystem?: string | null
  deliveryUniqueName?: string | null
  cargoIntervalId?: string | null
}
export type CodParamsEgo = {
  name?: string | null
  accountNumber?: string | null
  bik?: string | null
  correspondAccount?: string | null
  inn?: string | null
  kpp?: string | null
  bankName?: string | null
}
export type PlaceEgo = {
  id?: string | null
  description?: string | null
  barcode?: string | null
  fboBarcode?: string | null
  dimension?: DimensionEgo | null
  index?: string | null
  giveToCourierDate?: string | null
  stickerValue?: string | null
  stickerPdf?: string[] | null
  stickerZpl?: string | null
  litePacked?: boolean | null
  externalId?: string | null
  lpNumber?: string | null
  solutionCode?: string | null
  subPieces?: SubPieceEgo[] | null
}
export type DimensionEgo = {
  weight?: number | null
  width?: number | null
  height?: number | null
  depth?: number | null
}
export type SubPieceEgo = {
  id?: string | null
  createTs?: string | null
  updateTs?: string | null
  description?: string | null
  internationalDescription?: string | null
  price?: number | null
  quantity?: number | null
  vatSum?: number | null
  vatType?:
    | 'VAT18'
    | 'VAT20'
    | 'VAT10'
    | 'VAT_CALC_20_120'
    | 'VAT_CALC_18_118'
    | 'VAT_CALC_10_110'
    | 'VAT0'
    | 'NO_VAT'
    | 'VATX'
    | null
  tnvedCode?: string | null
  vendorCode?: string | null
  marketPlaceCode?: string | null
  received?: boolean | null
  receivedQuantity?: number | null
  barcode?: string | null
  boxNeeded?: boolean | null
  countryOfOrigin?: string | null
  weight?: number | null
  marketplaceSkuId?: string | null
  controlIdMarks?: string | null
  controlIdMarksMask?: string | null
  jwUin?: string | null
  customData?: CustomDataEgo[] | null
  index?: number | null
  priceNoVat?: number | null
  summaryPrice?: number | null
  summaryPriceNoVat?: number | null
  vatPrice?: number | null
  skuCode?: string | null
  summaryVatPrice?: number | null
  externalId?: string | null
}
export type CustomDataEgo = {
  paramName: string | null
  paramValue: string | null
}
export type PalletEgo = {
  id?: string | null
  description?: string | null
  barcode?: string | null
  fboBarcode?: string | null
  dimension?: DimensionEgo | null
  index?: string | null
  giveToCourierDate?: string | null
  stickerValue?: string | null
  stickerPdf?: string[] | null
  stickerZpl?: string | null
  placeList?: PlaceEgo[] | null
}
export type ServiceParamEgo = {
  courierServices?: CourierServices | null
  courierServiceCode?: string | null
}
export type MegamarketDataDtoEgo = {
  merchantName?: string | null
  merchantId?: string | null
  warehouseId?: string | null
  deliveryId?: string | null
  fullData?: boolean | null
}
export type GetShipmentsDlvListApiResponse = {
  content: ShipmentRequest[]
  empty: boolean
  first: boolean
  last: boolean
  number: number
  numberOfElements: number
  size: number
  totalElements: number
  totalPages: number
  pageable: Pageable
  sort: MonitoringContentSort
}

export type reportDataTypeEnum =
  | 'SHIPMENT_REQUEST'
  | 'SHIPMENT_REQUEST_BY_FILE'
  | 'TARIFF'
  | 'POSTAMAT'
  | 'GEOGRAPHY'
