import { useQFormContext } from '@/components/QForm/useQFormContext'
import React, { FC } from 'react'
import { Button, Tooltip } from '@chakra-ui/react'
import { ButtonSubmit, QForm } from '@/components/QForm'

export const QFormSubmitButton: FC<ButtonSubmit> = ({
  children = 'Сохранить',
  onClose = () => {},
  ...props
}) => {
  const {
    isValid,
    formik: { handleSubmit, isSubmitting, isValidating, dirty },
  } = useQFormContext()

  return (
    <Tooltip label={isValid ? undefined : <QForm.Errors />}>
      <Button
        onClick={() => {
          handleSubmit()
          onClose()
        }}
        isLoading={isSubmitting || isValidating}
        variant={dirty ? 'accent' : 'secondary'}
        isDisabled={!isValid}
        {...{ ...props }}
      >
        {children}
      </Button>
    </Tooltip>
  )
}
