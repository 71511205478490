import { lazy } from 'react'

export const RedirectAuthComponentLazy = lazy(
  () => import('./help/RedirectAuthComponent'),
)
export const AuthLazy = lazy(() => import('@/containers/Auth'))
export const LogoutLazy = lazy(() => import('@/containers/Logout'))
export const RegistrationLazy = lazy(() => import('@/containers/Registration'))
export const RegistrationSuccessLazy = lazy(
  () => import('@/containers/RegistrationSuccess'),
)
export const ResetPasswordLazy = lazy(
  () => import('@/containers/ResetPassword'),
)
export const ResetPasswordSuccessLazy = lazy(
  () => import('@/containers/ResetPasswordSuccess'),
)
export const SetPasswordLazy = lazy(() => import('@/containers/SetPassword'))
export const ShipmentsLazy = lazy(() => import('@/containers/Shipments'))
export const ShipmentsExportLazy = lazy(
  () => import('@/containers/Shipments/Export/ShipmentsExportPage'),
)
export const RunRequestsLazy = lazy(() => import('@/containers/RunRequests'))
export const ShipmentCardLazy = lazy(() => import('@/containers/ShipmentCard'))
export const ShipmentFromId = lazy(
  () => import('@/_app/pages/shipmentsFromId/ShipmentsFromIdPage'),
)
export const RunRequestFlowLazy = lazy(
  () => import('@/containers/RunRequestCard'),
)
export const AcceptanceLazy = lazy(() => import('@/containers/Acceptance'))
export const YandexRoutesLazy = lazy(() => import('@/containers/YandexRoutes'))
export const TransferToTheDeliveryServiceLazy = lazy(
  () => import('@/containers/TransferToTheDeliveryServicePage'),
)
export const PageLayoutLazy = lazy(() => import('@/components/PageLayout'))
export const ProtectedRouteLazy = lazy(() => import('./ProtectedRoute'))

export const BillingTariffsFromId = lazy(
  () => import('@/_app/pages/billing/tariffs/TariffsFromIdPage'),
)

export const BillingBasicTariffsFromId = lazy(
  () => import('@/_app/pages/billing/basic/tariffs/BasicTariffsFromIdPage'),
)
