import {
  LocationStoreRequest,
  PaginationResponse,
  PostInternalLocationsApiResponse,
  qubyApiPhp,
} from '@/api/generated/qubyApiPhp'
import {
  DlvError,
  DownloadProblemOrderRequest,
  EstimatedDeliveryTimeReportDto,
  ExportPostalBaseTariffExcelApiArg,
  ShipmentRequest,
  ShipmentUploadRequest,
} from '@/api/generated/qubyApiDlv'
import {
  CourierCompanyDto,
  GetCourierCompaniesApiArg,
  GetCourierCompaniesApiResponse,
  GetMonitoringLogApiArg,
  GetMonitoringLogApiResponse,
  HistoriesType,
  MonitoringContent,
  MonitoringDlvStatus,
  MonitoringStatus,
  PlacesList,
  PostMonitoringUploadingToCsByIdApiArg,
  PostMonitoringUploadingToCsByIdApiResponse,
  ReturnDocDto,
  ShipmentRequestEgo,
} from '@/api/qubyApiEgoDlv'

const envVariables = require('@/constants/env')

const convertParamMonitoring = (arg: GetInternalMonitoringDlvApiArg) => {
  return {
    query: arg.query,
    dateFrom: arg.dateFrom,
    dateTo: arg.dateTo,
    dlvStatuses:
      //@ts-ignore
      arg.dlvStatuses?.length ? arg.dlvStatuses.join(',') : arg.dlvStatuses,
    courierStatuses:
      arg.courierStatuses?.length ?
        //@ts-ignore
        arg.courierStatuses.join(',')
      : arg.courierStatuses,

    currentPage: arg.currentPage,
    perPage: arg.perPage,
    statusDuration: arg.statusDuration,
    filterType: arg.filterType,
    warehouse: arg.warehouse,
    updating: arg.updating,
  }
}

export const qubyApiPhpEgo = qubyApiPhp.injectEndpoints({
  endpoints: (build) => ({
    putInternalLocations: build.mutation<
      PostInternalLocationsApiResponse,
      PutInternalLocationsApiArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/locations/${queryArg.id}`,
          method: 'PUT',
          body: queryArg.locationStoreRequest,
        }
      },
      invalidatesTags: ['Locations'],
    }),
    getInternalShipmentsDlvList: build.query<
      GetInternalShipmentsDlvListApiResponse,
      GetInternalShipmentsDlvListApiArg
    >({
      query: (queryArg) => ({
        url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/shipments`,
        params: {
          query: queryArg.query,
          order: queryArg.order,
          orderBy: queryArg.orderBy,
          currentPage: queryArg.currentPage,
          perPage: queryArg.perPage,
          num: queryArg.num,
          status: queryArg.status,
          syncState: queryArg.syncState,
          courierNum: queryArg.courierNum,
          barcode: queryArg.barcode,
          dlvId: queryArg.dlvId,
          courierCode: queryArg.courierCode,
          id: queryArg.id,
          dateFrom:
            queryArg.dateFrom ?
              `${queryArg.dateFrom}T00:00:00`
            : queryArg.dateFrom,
          dateTo:
            queryArg.dateTo ? `${queryArg.dateTo}T00:00:00` : queryArg.dateTo,
        },
      }),
    }),
    getInternalShipmentDlvById: build.query<
      GetInternalShipmentDlvGetByIdResponse,
      GetInternalShipmentDlvGetByIdArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/shipments/${queryArg?.id}`,
        }
      },
      providesTags: [],
    }),
    postInternalShipmentDlv: build.mutation<
      PostInternalShipmentDlvByIdResponse,
      PostInternalShipmentDlvApiArg
    >({
      query: (queryArg) => ({
        url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/shipments`,
        method: 'POST',
        body: queryArg.shipmentUpdateRequest,
      }),
      invalidatesTags: [],
    }),
    getInternalPlacesDlvList: build.query<
      GetInternalPlacesDlvApiResponse,
      GetInternalPlacesDlvListApiArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/places/${queryArg?.id}`,
          params: {
            order: queryArg.order,
            orderBy: queryArg.orderBy,
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }
      },
    }),
    getInternalHistoriesDlvList: build.query<
      GetInternalHistoriesDlvApiResponse,
      GetInternalHistoriesDlvApiArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/histories/${queryArg?.id}`,
          params: {
            currentPage: queryArg.currentPage,
            perPage: queryArg.perPage,
          },
        }
      },
    }),
    reportProblemOrders: build.mutation<
      ReportOrderApiResponse,
      DownloadProblemOrderRequest
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/reports/problem-orders`,
          method: 'POST',
          body: queryArg,
        }
      },
    }),
    reportOrder: build.mutation<ReportOrderApiResponse, ShipmentUploadRequest>({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/reports/orders`,
          method: 'POST',
          body: queryArg,
        }
      },
    }),
    reportPostTariffs: build.mutation<
      ReportOrderApiResponse,
      ExportPostalBaseTariffExcelApiArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/reports/post-tariffs`,
          params: queryArg,
        }
      },
    }),
    reportDeliveryTime: build.mutation<
      ReportOrderApiResponse,
      EstimatedDeliveryTimeReportDto
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/reports/delivery-time`,
          method: 'POST',
          body: queryArg,
        }
      },
    }),
    getInternalMonitoringDlvList: build.query<
      GetInternalMonitoringDlvApiResponse,
      GetInternalMonitoringDlvApiArg
    >({
      query: (queryArg) => {
        const originalParams = convertParamMonitoring(queryArg)
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/monitoring/orders-info`,
          method: 'GET',
          params: {
            ...originalParams,
          },
        }
      },
    }),
    getInternalMonitoringDlvById: build.query<
      GetInternalMonitoringDlvByIdApiResponse,
      GetInternalMonitoringDlvByIdApiArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/monitoring/order-info`,
          method: 'GET',
          params: {
            dlvId: queryArg?.dlvId,
            updating: queryArg?.updating,
          },
        }
      },
    }),
    getInternalMonitoringDlvLog: build.query<
      GetMonitoringLogApiResponse,
      GetMonitoringLogApiArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/monitoring/exchange`,
          method: 'GET',
          params: {
            type: queryArg.type,
            id: queryArg.id,
          },
        }
      },
    }),
    getInternalPlanReturnsDlvList: build.query<
      GetInternalPlanReturnsDlvListApiResponse,
      GetInternalPlanReturnsDlvListApiArg
    >({
      query: (queryArg) => ({
        url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/returns/planReturnsAll`,
        method: 'GET',
        params: {
          dateFrom: queryArg.dateFrom,
          dateTo: queryArg.dateTo,
          docNumber: queryArg.query || undefined,
          warehouse: queryArg.warehouse,
          courierCode: queryArg.courierCode,
        },
      }),
    }),
    postMonitoringUploadingToCsById: build.mutation<
      PostMonitoringUploadingToCsByIdApiResponse,
      PostMonitoringUploadingToCsByIdApiArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/shipments/commitShipmentRequestList`,
          method: 'POST',
          body: {
            dlvIdList: queryArg?.dlvIdList,
          },
        }
      },
      invalidatesTags: [],
    }),
    getInternalCourierCompaniesDlv: build.query<
      GetInternalCourierCompaniesDlvApiResponse,
      GetInternalCourierCompaniesDlvApiArg
    >({
      query: (queryArg) => {
        return {
          url: `${envVariables.PHP_API_URL}v1.1/internal/dlv/couriers-info`,
          method: 'GET',
          params: {
            active: queryArg.active,
            marketplace: queryArg.marketplace,
          },
        }
      },
    }), //getCourierCompanies
  }),
  overrideExisting: true,
})

export type PutInternalLocationsApiArg = {
  id: string
  locationStoreRequest: LocationStoreRequest
}
export type RunRequestStatusForChangeStatusEnum =
  | 'NEW'
  | 'IN_PROGRESS'
  | 'DONE'
  | 'CANCELLED'
  | 'ERROR'

export type ReportOrderApiResponse = {
  file?: string
  fileName?: string
  success?: boolean
  /** Описание ошибки */
  errors?: DlvError[]
}

export type GetInternalShipmentsDlvListApiArg = {
  currentPage?: number
  perPage?: number
  order?: string
  orderBy?: string
  query?: string
  num?: string
  status?: string
  syncState?: string
  courierNum?: string
  createTsFrom?: string
  createTsTo?: string
  barcode?: string
  dlvId?: string
  courierCode?: string
  id?: string
  dateFrom?: string
  dateTo?: string
}

export type GetInternalShipmentsDlvListApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: ShipmentRequest[]
  }

export type GetInternalPlacesDlvListApiArg = {
  currentPage?: number
  perPage?: number
  order?: string
  orderBy?: string
  id: string
}

export type GetInternalPlacesDlvApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: PlacesList[]
  }

export type GetInternalHistoriesDlvApiArg = {
  currentPage?: number
  perPage?: number
  id: string
}

export type GetInternalHistoriesDlvApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: HistoriesType[]
  }

export type GetInternalShipmentDlvGetByIdArg = {
  id: string
}

export type GetInternalShipmentDlvGetByIdResponse =
  /** status 200 Success */ ShipmentRequestEgo

export type PostInternalShipmentDlvApiArg = {
  shipmentUpdateRequest: ShipmentRequestEgo
}

export type PostInternalShipmentDlvByIdResponse =
  /** status 200 Success */ ShipmentRequestEgo

export type GetInternalMonitoringDlvApiArg = {
  statusDuration?: string
  dlvStatuses?: MonitoringDlvStatus[] | string
  courierStatuses?: MonitoringStatus[] | string
  filterType?: string
  num?: string
  currentPage?: number
  perPage?: number
  query?: string
  dateFrom?: string
  dateTo?: string
  year?: number
  month?: string | number
  week?: string | number
  day?: string | number
  hour?: string | number
  minute?: string | number
  second?: string | number
  updating?: string
  warehouse?: string
}

export type GetInternalMonitoringDlvApiResponse =
  /** status 200 Success */ PaginationResponse & {
    data: MonitoringContent[]
  }

export type GetInternalMonitoringDlvByIdApiArg = {
  query?: string
  dlvId?: string
  updating?: string
}

export type GetInternalMonitoringDlvByIdApiResponse =
  /** status 200 Success */ MonitoringContent

export type GetInternalPlanReturnsDlvListApiArg = {
  dateFrom?: string
  dateTo?: string
  docNumber?: string
  query?: string
  warehouse?: string
  courierCode?: string
}

export type GetInternalPlanReturnsDlvListApiResponse =
  /** status 200 Success */ {
    data: ReturnDocDto[]
  }

export type GetInternalCourierCompaniesDlvApiArg = {
  active?: boolean
  marketplace?: boolean
}

export type GetInternalCourierCompaniesDlvApiResponse =
  /** status 200 Success */ {
    data: CourierCompanyDto[]
  }
