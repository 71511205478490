import { format as _format } from 'date-fns'
import { ru } from 'date-fns/locale'

export function format(date: string, patern = 'dd MMMM yyyy'): string {
  return _format(new Date(date), patern, { locale: ru })
}

export function dateToString(date: Date): string {
  return `${date.getFullYear()}-${(date.getMonth() + 1)
    .toString()
    .padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`
}

export function stringToDate(date: string): Date | null {
  if (date === '') return null
  const parse = date.split('-')
  if (parse.length === 3) {
    const [year, month, day] = parse.map((i) => parseInt(i))
    return new Date(Date.UTC(year, month - 1, day))
  } else if (parse.length === 2) {
    return new Date(date)
  } else {
    console.error('date parse with err in dateHelperFns.ts')
    return null
  }
}

export function timeToString(date: Date): string {
  return `${date.getHours().toString().padStart(2, '0')}:${date
    .getMinutes()
    .toString()
    .padStart(2, '0')}:00`
}

export function stringToTime(time: string): Date | null {
  if (time === '') return null
  const parse = time.split(':')
  if (parse.length === 3) {
    const [hours, minutes, seconds] = parse.map((i) => parseInt(i))
    const date = new Date(Date.now())
    return new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      hours,
      minutes,
      seconds,
    )
  } else {
    console.error('time parse with err in dateHelperFns.ts')
    return null
  }
}

export function formatDate(
  d: string,
  options?: {
    withTime?: boolean
    intlExtraOptions?: Intl.DateTimeFormatOptions
  },
) {
  const date = new Date(d.replace(/\..*$/, ''))
  return new Intl.DateTimeFormat(undefined, {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    ...(options?.withTime ?
      {
        hour: '2-digit',
        minute: '2-digit',
      }
    : {}),
    ...(options?.intlExtraOptions ? options.intlExtraOptions : {}),
  }).format(date)
}

export function removeSeconds(str: string) {
  return str.replace(/:\d{2}$/, '')
}
